import { createSlice } from "@reduxjs/toolkit";
import { faqs } from "../../components/Accordion";
const initialValue = {
   userInfo: {},
   isWalletConnected:false,
   network:{},
   config:{},
   tronConfig:{},
   allData:{},
   data:"",
   update:faqs,

  //  tabactive: {
  //   analog: true,
  //     eth: false,
  //     solana: false,
  //     poly: false,
  //     bnb: false,
  //  },
};

export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: initialValue,
  },
  reducers: {
    setisWalletConnected : (state, action) =>{
      state.value.isWalletConnected = action.payload.isWalletConnected
    },
    setConfig: (state, action) => {
      state.value.config = action.payload.config;
    },
    setNetwork: (state, action) => {
      state.value.network = action.payload.network;
    },
    setTronConfig: (state, action) => {
      state.value.tronConfig = action.payload.tronConfig;
    },
    setAllData: (state, action) => {
      state.value.allData = action.payload.allData;
    },
    setData: (state, action) => {
      state.value.data = action.payload.data;
    },
    setupate: (state, action) => {
      state.value.update = action.payload.update;
    },
  
    // setTabActive: (state, action) => {
    //   state.value.tabactive = action.payload.tabactive;
    // },
  },
});

export const {
  setisWalletConnected,
  setNetwork,
  setTronConfig,
  setConfig,
  setAllData,
  setData,
  setupate,

  // setTabActive,
} = userSlice.actions;
export default userSlice.reducer;
