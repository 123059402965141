import React from "react";
import Footer from "../Footer";
import { Header } from "../Header";

export default function Terms_and_conditions() {
  return (
    <>
      <div className="tron-usdd-wrapper bg_psm content_style">
        <Header />
        <div className="page-content">
          <div className="container">
            <div className="spacer">
              <h1 class="heading">
                Terms and <span className="span-gradient">Conditions</span>
              </h1>
              <p className="text-gray fs-14">
                These Terms and Conditions ("Terms") govern your access to and
                use of our services, including our website, mobile applications,
                and any other products or services provided by INRx
                (collectively, "Services"). By accessing or using our Services,
                you agree to be bound by these Terms. If you do not agree to
                these Terms, please do not use our Services.
              </p>
            </div>

            <h2>1. Definitions</h2>
            <h3>1.1 Key Terms</h3>
            <ul className="list">
              <li>
                <strong>INRx:</strong> The stable cryptocurrency pegged to the
                Indian Rupee.
              </li>
              <li>
                <strong>User:</strong> Any individual or entity using the
                Services.
              </li>
              <li>
                <strong>Wallet:</strong> A digital wallet used to store, send,
                and receive INRx.
              </li>
              <li>
                <strong>Transaction:</strong> Any transfer of INRx between
                wallets.
              </li>
              <li>
                <strong>Services:</strong> All products, services, and content
                provided by INRx.
              </li>
            </ul>

            <h2>2. Eligibility</h2>
            <h3>2.1 Age Requirement</h3>
            <p>
              You must be at least 18 years old to use our Services. By using
              our Services, you represent and warrant that you meet this age
              requirement.
            </p>
            <h3>2.2 Compliance with Laws</h3>
            <p>
              You agree to comply with all applicable laws and regulations when
              using our Services. You are responsible for ensuring that your use
              of the Services is in compliance with the laws of your
              jurisdiction.
            </p>

            <h2>3. Account Registration</h2>
            <h3>3.1 Creating an Account</h3>
            <p>
              To use certain features of our Services, you may need to create an
              account. You agree to provide accurate, current, and complete
              information during the registration process and to update such
              information as necessary.
            </p>
            <h3>3.2 Account Security</h3>
            <p>
              You are responsible for maintaining the confidentiality of your
              account credentials and for any activities that occur under your
              account. You agree to notify us immediately of any unauthorized
              use of your account.
            </p>

            <h2>4. Use of Services</h2>
            <h3>4.1 Acceptable Use</h3>
            <p>
              You agree to use our Services only for lawful purposes and in
              accordance with these Terms. You agree not to:
            </p>
            <ul className="list">
              <li>
                Use the Services in any manner that could disable, overburden,
                or impair the Services.
              </li>
              <li>
                Engage in any fraudulent, misleading, or deceptive activities.
              </li>
              <li>
                Interfere with or disrupt the integrity or performance of the
                Services.
              </li>
            </ul>

            <h3>4.2 Prohibited Activities</h3>
            <p>
              You agree not to engage in any of the following prohibited
              activities:
            </p>
            <ul className="list">
              <li>
                <strong>Hacking:</strong> Attempting to gain unauthorized access
                to our systems or networks.
              </li>
              <li>
                <strong>Fraud:</strong> Using the Services to engage in
                fraudulent or illegal activities.
              </li>
              <li>
                <strong>Abuse:</strong> Harassing, threatening, or abusing other
                users or our staff.
              </li>
            </ul>

            <h2>5. Transactions</h2>
            <h3>5.1 Transaction Fees</h3>
            <p>
              We may charge fees for certain transactions. Any applicable fees
              will be disclosed to you prior to completing the transaction.
            </p>
            <h3>5.2 Transaction Limits</h3>
            <p>
              We may impose limits on the amount of INRx that can be transferred
              or held in your wallet. These limits may vary based on your
              account status and verification level.
            </p>
            <h3>5.3 Transaction Finality</h3>
            <p>
              All transactions are final and cannot be reversed once completed.
              You are responsible for ensuring the accuracy of transaction
              details before confirming the transaction.
            </p>

            <h2>6. Privacy</h2>
            <p>
              Our Privacy Policy governs the collection, use, and disclosure of
              your personal information. By using our Services, you agree to the
              terms of our Privacy Policy.
            </p>

            <h2>7. Intellectual Property</h2>
            <h3>7.1 Ownership</h3>
            <p>
              All intellectual property rights in the Services, including
              software, content, and trademarks, are owned by INRx or its
              licensors. You are granted a limited, non-exclusive,
              non-transferable license to use the Services for personal,
              non-commercial purposes.
            </p>
            <h3>7.2 Restrictions</h3>
            <p>You agree not to:</p>
            <ul className="list">
              <li>Copy, modify, or create derivative works of the Services.</li>
              <li>
                Reverse engineer or attempt to extract the source code of the
                Services.
              </li>
              <li>
                Use the Services to develop competing products or services.
              </li>
            </ul>

            <h2>8. Termination</h2>
            <h3>8.1 Termination by User</h3>
            <p>
              You may terminate your account at any time by contacting us. Upon
              termination, you must cease all use of the Services.
            </p>
            <h3>8.2 Termination by INRx</h3>
            <p>
              We may terminate or suspend your account and access to the
              Services if you violate these Terms or engage in prohibited
              activities. We will provide notice of termination or suspension,
              except where prohibited by law.
            </p>

            <h2>9. Limitation of Liability</h2>
            <h3>9.1 Disclaimer</h3>
            <p>
              The Services are provided "as is" and "as available" without any
              warranties of any kind. We disclaim all warranties, whether
              express or implied, including but not limited to warranties of
              merchantability, fitness for a particular purpose, and
              non-infringement.
            </p>
            <h3>9.2 Limitation</h3>
            <p>
              To the maximum extent permitted by law, INRx will not be liable
              for any indirect, incidental, special, consequential, or punitive
              damages, or any loss of profits or revenues, whether incurred
              directly or indirectly, or any loss of data, use, goodwill, or
              other intangible losses, resulting from:
            </p>
            <ul className="list">
              <li>Your use or inability to use the Services.</li>
              <li>Unauthorized access to or use of your account.</li>
              <li>
                Any conduct or content of any third party on the Services.
              </li>
            </ul>

            <h2>10. Indemnification</h2>
            <p>
              You agree to indemnify, defend, and hold harmless INRx and its
              affiliates, officers, directors, employees, and agents from and
              against any claims, liabilities, damages, losses, and expenses,
              including reasonable legal fees, arising out of or in any way
              connected with:
            </p>
            <ul className="list">
              <li>Your access to or use of the Services.</li>
              <li>Your violation of these Terms.</li>
              <li>Your violation of any rights of another party.</li>
            </ul>

            <h2>11. Governing Law and Dispute Resolution</h2>
            <h3>11.1 Governing Law</h3>
            <p>
              These Terms are governed by and construed in accordance with the
              laws of India, without regard to its conflict of laws principles.
            </p>
            <h3>11.2 Dispute Resolution</h3>
            <p>
              Any disputes arising out of or relating to these Terms or the
              Services will be resolved through binding arbitration in
              accordance with the rules of the Arbitration and Conciliation Act,
              1996. The arbitration will be conducted in [City], India, and the
              language of the arbitration will be English.
            </p>

            <h2>12. Changes to These Terms</h2>
            <p>
              We may update these Terms from time to time to reflect changes in
              our practices or legal requirements. The updated Terms will be
              posted on our website, and the effective date will be indicated.
              Your continued use of the Services after the effective date
              constitutes your acceptance of the updated Terms.
            </p>

            <h2>13. Contact Us</h2>
            <p>
              If you have any questions or concerns about these Terms, please
              contact us at:
            </p>
            <p>
              <strong>Email:</strong> support@inrx.io
              <br />
              <strong>Address:</strong> INRx Support Office, [Address], India
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
