import React from "react";
import { Header } from "./Header";


export default function HistoryTrans() {
  const i = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  return (
    <>
       <Header/>
      <div className="container">
        <div className="row">
          <h4 className="text-light pt-5">Issuance History</h4>
          <p className="text-light">
            USDD transaction records of the issuance contract, authorized
            contract, and circulation account
          </p>

          {i.map((data) => {
            return (
              <>
                <div
                  className="card mt-3 mb-3"
                  style={{ background: "#272730", borderRadius: "10px" }}
                >
                  <div className="card-header">
                    <div className="d-flex text-light justify-content-between">
                      <div>
                        <p>TxID d774ce... 566cfa</p>
                      </div>
                      <div>
                        <p>2022-06-01 04:42:48</p>
                      </div>
                    </div>
                  </div>
                  <div className="card-body text-light">
                    <blockquote className="blockquote mb-0">
                      <p>Issued 20,000,000 USDD to the circulation account.</p>
                    </blockquote>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}
