import React from 'react'
import { Link } from 'react-router-dom';
import { useLocation} from "react-router-dom";
import { useNetwork } from '../helpers/useNetwork';

export default function BlockchainTabs() {
    const location = useLocation();
    const netw = useNetwork();
    const networks = netw[0];
    const CurrentINRxPrice = netw[1];
    // console.log(CurrentINRxPrice, "INR::::PRICE")
    console.log(networks,netw,"networks123");
    return (
        <>
            <div className="mb-3">
                <ul className= {window.innerWidth >=500?"nav nav-tabs container mobile-screen":"nav nav-tabs mobile-screen"}>
                    <li
                        className="nav-item"
                       
                    >
                        <Link
                            // to="/cps-swap/analog"
                            to="/analog/cps"
                            className={
                                // tabactive.analog == truechain
                                networks === "analog"
                                    ? "nav-link active bg-transparent"
                                    : "nav-link bg-transparent"
                            }
                            aria-current="page"
                            style={{pointerEvents:"none", cursor:"none"}}
                            
                        >
                            <img src="/img/tabs_img/Analog_icon new.svg" />
                        </Link>
                    </li>
                    <li
                        className="nav-item"
                      

                    >
                        <Link
                            to="/ethereum/cps"
                            className={
                                // tabactive?.eth === true
                                networks === "ethereum"
                                    ? "nav-link active bg-transparent"
                                    : "nav-link bg-transparent"
                            }
                            aria-current="page"
                            style={{pointerEvents:"none", cursor:"none"}}
                            
                        >
                            <img src="/img/tabs_img/eth.svg" />
                        </Link>
                    </li>
                    <li
                        className="nav-item"
                       
                    >
                        <Link
                            to="/bsc/cps"
                            className={
                                // tabactive?.bnb === true
                                networks === "bsc"
                                    ? "nav-link active bg-transparent"
                                    : "nav-link bg-transparent"
                            }
                            style={{pointerEvents:"none"}}
                        >
                            <img src="/img/tabs_img/bsc.svg" />
                        </Link>
                    </li>
                    <li
                        className="nav-item"
                      
                    >
                        <Link
                            to="/polygon/cps"
                            className={
                                networks === "polygon"
                                    // tabactive?.poly === true
                                    ? "nav-link active bg-transparent"
                                    : "nav-link bg-transparent"
                            }
                            style={{pointerEvents:"none", cursor:"none"}}
                            
                        >
                            <img src="/img/tabs_img/polygon.svg" />
                        </Link>
                    </li>
                    <li
                        className="nav-item"
                       
                    >
                        <Link to="/cps-swap/tronSwap"
                            className={
                                location.pathname === "/cps-swap/tronSwap"
                                    ? "nav-link active bg-transparent"
                                    : "nav-link bg-transparent"
                            }
                            style={{pointerEvents:"none", cursor:"none"}}
                            
                        >
                            <img src="/img/tabs_img/tron.svg" />
                        </Link>
                    </li>
                    {/* <li
                    className="nav-item"
                    onClick={() => {
             
                      dispatch(
                        setTabActive({
                          tabactive: {
                            analog: false,
                            eth: false,
                            solana: true,
                            poly: false,
                            bnb: false,
                            tron: false,
                          },
                        })
                      );
                    }}
                  >
                    <Link to="/solanaSwap"
                      className={
                        tabactive?.solana === true
                          ? "nav-link active bg-transparent"
                          : "nav-link bg-transparent"
                      }
                    >
                      <img src="/img/tabs_img/solana.svg" />
                    </Link>
                  </li> */}
                </ul>
            </div>
        </>
    )
}
