import React from "react";
import Footer from "./Footer";
import { Header } from "./Header";
import { Link } from "react-router-dom";

export default function FeeDetails() {
  return (
    <>
      <div className="tron-usdd-wrapper bg_psm">
        <Header />
        <div className="page-content">
          <div className="container section-container--">
            <div className="">
              <h1 class="heading mb-4">
                <span className="span-gradient"> Fees </span> Details
              </h1>
            </div>

            <div className="">
              <div class="row justify-content-center">
                <div className="col-lg-4 mb-4">
                  <div class="custom_card ">
                    <div className="preview_card_body">
                      <h4 class="mb-2">Minimum INRx Token</h4>
                      <p className="text-gray fs-14">100,00 INR</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-4">
                  <div class="custom_card ">
                    <div className="preview_card_body">
                      <h4 class="mb-2">Fee per redemption</h4>
                      <p className="text-gray fs-14">
                        The greater of ₹ 10,000 or 0.1%
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 mb-4">
                  <div class="custom_card ">
                    <div className="preview_card_body">
                      <h4 class="mb-2">Fee per acquisition</h4>
                      <p className="text-gray fs-14">0.1%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="spacer">
              <div className="col-lg-12">
                <div className="custom_card_outline">
                  <div className="card-body">
                    <h3 className="h3 mb-3">Verification Fee</h3>
                    <h4 className="h5">
                      <span className="span-gradient">
                        1500 INR in INRx {" "}
                      </span>
                      Tokens
                    </h4>
                    <p>
                      The verification fee for establishing an account with INRx
                      is non-refundable but can be applied towards your
                      redemption. This fee is designed to ensure that only those
                      who are serious about creating an account apply, and it
                      also helps to offset the costs associated with our
                      thorough verification process. INRx retains sole
                      discretion in approving or denying account applications.
                      By submitting a verification request, you agree that the
                      1500 INRx deposit is non-refundable. Please read all terms
                      and conditions carefully before proceeding.
                    </p>

                    <div className="my-3">
                      <Link to="https://auth.inrx.io" className="btn-hover color-7">Subscribe Now</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="spacer">
              <div className="col-lg-12">
                <div className="custom_card_outline">
                  <div className="card-body">
                    <h3 className="h3 mb-3">Subscription Fee</h3>
                    <h4 className="h5">
                      {" "}
                      <span className="span-gradient">
                       
                       24,999 INR in INRx{" "}
                      </span>
                      Tokens
                    </h4>
                    <p>
                      To convert your stable assets like USDT or USDC into INRx
                      or INR with a premium subscription, a verification fee of
                      24,999 INRx is required. This fee is non-refundable but
                      can be applied towards your redemption. The fee ensures
                      that only serious applicants proceed and helps offset the
                      costs associated with our comprehensive verification
                      process. INRx retains the sole discretion to approve or
                      deny account applications. By submitting a verification
                      request, you agree to the non-refundable 24,999 INRx
                      verification fee. Please read all terms and conditions
                      carefully before proceeding.
                    </p>

                    <div className="my-3">
                      <Link to="https://auth.inrx.io" className="btn-hover color-7">Subscribe Now</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}
