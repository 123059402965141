import React from "react";
import { Link } from "react-router-dom";

export default function Card_Ecosystem(props) {
  const { id, Logo, Name, Pair_logo, Pair_Name, Stake_amount, Apy } =
    props;
  return (
    <>
      <div className="col-md-4">
        <Link to="/staking">
          <div className="scrollbar-container ps ps--active-y  ">
            <div className="scrollbar-wrapper">
              <div className="ecology-list">
                <Link to="/staking"></Link>
                <div className="item my-card p p-3">
                  {/* <div className="item"></div> */}
                  <div className="type-box">
                    <div className="token flex-center">
                      <img src={Logo} alt="" />
                      <span>{Name}</span>
                    </div>
                  </div>
                  <div className="item-top flex-start">
                    <div className="pool-fire"></div>
                    <div className="origin-box">
                      <img src={Pair_logo} style={{ height: "25px" }} />
                      <h5>
                        <span>INR<span style={{ color: "#03e804" }}>x</span>-{Pair_Name}</span>
                        <div className="label-wrapper">
                          <span className="label_flexible">Flexible</span>
                          <span className="label_fixed">Fixed</span>
                        </div>
                      </h5>
                      <div>
                        {/* <span>SUN.io</span>
                                  <img src="https://tdr-image.s3.us-east-2.amazonaws.com/logo/platform/SUN.svg" alt="" /> */}
                      </div>
                    </div>
                    <div className="item-bot">
                      <div className="flex-between d-flex">
                        <span>Total staked</span>
                        <span>APY</span>
                      </div>
                      <div className="flex-between d-flex">
                        <em>{Stake_amount}</em>
                        <b>
                          {Apy}
                          <i></i>
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}
