import React, { useRef, useState , useEffect} from "react";
import { HiOutlineStar } from 'react-icons/hi';
import { AiOutlineLink, AiOutlinePlus } from 'react-icons/ai';
import { BiTransferAlt } from 'react-icons/bi';
import { useDispatch, useSelector } from "react-redux";
import { setupate } from "../redux/reducer/user";

 export const faqs = [
    {
        id: 1,
        Name:"Analog",
        pair_logo: "/img/logo/INRX USDA.svg",
        logo: "/img/logo/inrx_symbol.svg",
        token:"USDA",
        apr: "5.23%"
    },
    {
        id: 2,
        Name:"Binance",
        pair_logo: "/img/INRX busd.svg",
        token:"BUSD",
        logo: "/img/logo/inrx_symbol.svg",
        apr: "5.23%"
    },
    {
        id: 3,
        Name:"Ethereum",
        pair_logo: "/img/INRX USDT.svg",
        logo: "/img/logo/inrx_symbol.svg",
        token:"USDT",
        apr: "5.23%"
    },
    {
        id: 4,
        Name:"Polygon",
        pair_logo: "/img/logo/INRX USDC.svg",
        logo: "/img/logo/inrx_symbol.svg",
        token:"USDC",
        apr: "5.23%"
    },
    // {
    //     id: 5,
    //     Name:"Tron",
    //     pair_logo: "/img/logo/INRX USDC.svg",
    //     logo: "/img/logo/inrx_symbol.svg",
    //     token:"USDC",
    //     apr: "5.23%"
    // }
]

const AccordionItem = (props) => {
    const contentEl = useRef();
    const { handleToggle, active, faq } = props;
    const { logo, pair_logo, id, apr, Name ,token} = faq;
    return (
        <div className="rc-accordion-card text-color ">
            <div className="rc-accordion-header">
                <div className={`rc-accordion-toggle p-3 ${active === id ? 'active' : ''}`} onClick={() => handleToggle(id)}>
                    <div className="row align-items-center">
                        <div className="row col-11 align-items-center">
                            <div className="col-1 text-center"><HiOutlineStar /></div>
                            <div className="col"> <span className="d-flex align-items-center"><img width={40} className="me-2" src={pair_logo} alt={`INRx-${token}`}></img>  <span className="d-flex align-items-center">INR <span className="text-success">x<span>-</span></span>{token} </span></span></div>
                            <div className="col text-center"><img width={25} className="me-2" src={logo} alt={`INRx-${token}`}></img></div>
                            <div className="col">{apr}</div>
                            <div className="col">
                                <div>$4.517,705</div>
                                <div>2.517,705 LP</div>
                            </div>
                            <div className="col">{Name}</div>
                        </div>
                        <div className="col-1 text-end"><i className="fa fa-chevron-down rc-accordion-icon"></i></div>
                    </div>
                </div>
            </div>
            <div ref={contentEl} className={`rc-collapse ${active === id ? 'show' : ''}`} style={
                active === id
                    ? { height: contentEl.current.scrollHeight }
                    : { height: "0px" }
            }>
                <div className="rc-accordion-body">
                    <div className="row align-items-center">
                        <div className="col-sm-5 col-4">
                            <div className="card-border">
                                <div className="row p-3">
                                    <div className="col">Deposited</div>
                                    <div className="col text-end">
                                        <div className="btn acc-btn fw-bold">Connect Wallet</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-5 col-5">
                            <div className="card-border mt-1">
                                <div className="row p-3">
                                    <div className="col">Deposited</div>
                                    <div className="col text-end">
                                        <div className="btn acc-btn fw-bold">Connect Wallet</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-sm-2 col-3">
                            <div className="d-flex justify-content-center mt-1">
                                <div className="card-border p-2 m-1">
                                    <AiOutlineLink
                                        style={{ fontSize: "30px" }}
                                    />
                                </div>
                                <div className="card-border p-2 m-1">
                                    <AiOutlinePlus
                                        style={{ fontSize: "30px" }}
                                    />
                                </div>
                                <div className="card-border p-2 m-1">
                                    <BiTransferAlt
                                        style={{ fontSize: "30px" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default function Accordion_fc(props) {
    const dispatch = useDispatch();
    const { data ,update} = useSelector((state) => state.user.value);
    // console.log(data,"props")
    const [active, setActive] = useState(null);
    // const [update, setupate] = useState(faqs)

    const handleToggle = (index) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }
    useEffect(()=>{
        // setupate(data)
        dispatch(setupate({update:data}))
    },[data])

    // console.log(cards,"CARDAS")
    return (
        <>
            <div className="row justify-content-center">
                <div className="col-12 mt-2">
                    {update && update.map((faq, index) => {
                        return (
                            <AccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />
                        )
                    })
                    }
                </div>
            </div>
        </>
    );
};
