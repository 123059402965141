import { configureStore } from "@reduxjs/toolkit";

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {combineReducers} from "redux";


import userReducer from '../reducer/user';

const reducers = combineReducers({
    user: userReducer,
    
   });
   

const persistConfig = {
    key: 'root',
    storage,
  }

  const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
    reducer: persistedReducer,
    // middleware: getDefaultMiddleware =>
    // getDefaultMiddleware({
    //   serializableCheck: false,
    // })
    // reducer: {
    //     user: userReducer,
    // }

})

