import React from 'react'
import { Link } from 'react-router-dom'
import Marquee from "react-fast-marquee";
import "../css/Marguee.css";
export default function Marqueee() {
  return (
    <>
        <Marquee className="" speed={30}>
            <div className="supportingInstitutions-list">
              <Link to="">
                <div className="item">
                  <img src="img/ma.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Huobi Global</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma2.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Gate.io</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma3.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Sunswap</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma4.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Uniswap</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma5.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Pancake</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma6.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Mexc Global</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma7.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Paloniex</span>
                  </div>
                </div>
              </Link>

              <Link to="">
                <div className="item">
                  <img src="img/ma9.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Kucoin</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma14.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Bybit</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma10.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Phemex</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma11.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Bibox</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma12.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Bitget</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma13.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Jubi</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma15.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Bitrue</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma16.png" alt="err" />
                  <div className="text-wrapper">
                    <span>XT.com</span>
                  </div>
                </div>
              </Link>
            </div>
          </Marquee>
          <Marquee className="" speed={45}>
            <div className="supportingInstitutions-list">
              <Link to="">
                <div className="item">
                  <img src="img/ma.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Huobi Global</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma2.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Gate.io</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma3.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Sunswap</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma4.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Uniswap</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma5.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Pancake</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma6.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Mexc Global</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma7.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Paloniex</span>
                  </div>
                </div>
              </Link>

              <Link to="">
                <div className="item">
                  <img src="img/ma9.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Kucoin</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma14.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Bybit</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma10.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Phemex</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma11.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Bibox</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma12.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Bitget</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma13.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Jubi</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma15.png" alt="err" />
                  <div className="text-wrapper">
                    <span>Bitrue</span>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="item">
                  <img src="img/ma16.png" alt="err" />
                  <div className="text-wrapper">
                    <span>XT.com</span>
                  </div>
                </div>
              </Link>
            </div>
          </Marquee>
    </>
  )
}
