import React from "react";
import Footer from "./Footer";
import { Header } from "./Header";

export default function Faq() {
  return (
    <>
      <div className="tron-usdd-wrapper bg_psm content_style faq">
        <Header />
        <div className="page-content">
          <div className="container section-container--">
            <div className="spacer pt-0">
              <h1 class="heading">
                Frequently Asked Questions <span className="span-gradient">   (FAQs) </span>
              </h1>
              <div className="text-gray">
                INRx offers a stable, secure, and efficient digital currency
                solution for a wide range of applications. Whether you are using
                it for everyday transactions, remittances, or investments, INRx
                provides a reliable alternative to traditional fiat currencies.
              </div>
            </div>

           <h4> What is INRx?</h4>
            <p>
              INRx is a stable cryptocurrency pegged 1:1 to the Indian Rupee
              (INR). It combines the stability of the Indian Rupee with the
              benefits of blockchain technology, offering a secure and efficient
              means of transaction. INRx is designed to facilitate digital
              payments while mitigating the volatility typically associated with
              cryptocurrencies.
            </p>

           <h4>How does INRx maintain its value?</h4>
            <p>
              INRx maintains its value through a combination of algorithmic
              controls, smart contracts, and collateralization. The system
              ensures that the value of one INRx remains equivalent to one INR
              by adjusting the supply in response to demand and holding reserves
              to back the issued tokens.
            </p>

           <h4>How can I acquire INRx?</h4>
            <p>
              You can acquire INRx by purchasing it through authorized exchanges
              or directly from the INRx platform. You will need to create an
              account, complete the necessary KYC (Know Your Customer)
              verification, and fund your account with INR or other supported
              currencies.
            </p>

           <h4>What can I do with INRx?</h4>
            <ul className="list ms-3">
              <li>Everyday Transactions: Pay for goods and services.</li>
              <li>Remittances: Send money to family and friends.</li>
              <li>
                Investments: Hold as a stable store of value or participate in
                investment opportunities.
              </li>
              <li>E-commerce: Make online purchases.</li>
            </ul>

           <h4>Is INRx secure?</h4>
            <p>
              Yes, INRx leverages the security features of blockchain
              technology. Transactions are encrypted and recorded on an
              immutable ledger, ensuring transparency and preventing tampering.
              Additionally, INRx adheres to strict security protocols and
              regulatory compliance to protect users' funds and data.
            </p>

           <h4>How does INRx protect against fraud?</h4>
            <p>
              INRx employs advanced monitoring and security measures to detect
              and prevent fraudulent activities. This includes real-time
              transaction monitoring, user verification processes, and
              collaboration with regulatory authorities to ensure compliance and
              address any suspicious activities.
            </p>

           <h4>What are the transaction fees for INRx?</h4>
            <p>
              Transaction fees for INRx are designed to be low and competitive.
              The exact fees may vary depending on the exchange or platform you
              use. Detailed fee structures can be found on the respective
              platforms' websites.
            </p>

           <h4>How do I store my INRx?</h4>
            <p>
              INRx can be stored in any digital wallet that supports the INRx
              token standard. It is recommended to use a secure wallet and
              follow best practices for safeguarding your private keys and
              recovery phrases.
            </p>

           <h4>
              What is the process for sending and receiving INRx?
            </h4>
            <p>
              To send INRx, you will need the recipient's wallet address. Simply
              enter the amount you wish to send and confirm the transaction. To
              receive INRx, provide your wallet address to the sender.
              Transactions are processed quickly and securely on the blockchain.
            </p>

           <h4>How transparent is INRx?</h4>
            <p>
              INRx is committed to transparency. The platform regularly
              publishes information about the total supply of INRx, reserve
              holdings, and other relevant data. Users can access this
              information on the INRx website and through various transparency
              reports.
            </p>

           <h4>Who can use INRx?</h4>
            <p>
              INRx is available to individuals and businesses alike. Whether you
              are a consumer looking to make digital payments or a business
              seeking to integrate a stable cryptocurrency into your operations,
              INRx offers a versatile solution.
            </p>

           <h4>How do I register for an INRx account?</h4>
            <p>
              To register for an INRx account, visit the INRx website or mobile
              app, provide the required personal information, and complete the
              KYC verification process. Once your account is verified, you can
              start using INRx for transactions.
            </p>

           <h4>What is KYC and why is it required?</h4>
            <p>
              KYC (Know Your Customer) is a regulatory requirement that helps
              prevent fraud, money laundering, and other illegal activities. It
              involves verifying the identity of users by collecting and
              confirming personal information. KYC ensures that the INRx
              platform operates securely and complies with legal standards.
            </p>

           <h4>
              Can I exchange INRx for other cryptocurrencies or fiat currencies?
            </h4>
            <p>
              Yes, INRx can be exchanged for other cryptocurrencies or fiat
              currencies on supported exchanges. The process involves trading
              your INRx for the desired currency at the current market rate.
            </p>

           <h4>How do I keep my INRx safe?</h4>
            <p>
              To keep your INRx safe, use a secure wallet, enable two-factor
              authentication (2FA) on your accounts, and avoid sharing your
              private keys or recovery phrases. Regularly update your security
              settings and be cautious of phishing attempts and other scams.
            </p>

           <h4>
              What should I do if I suspect fraudulent activity on my account?
            </h4>
            <p>
              If you suspect any fraudulent activity on your account, contact
              INRx support immediately. Provide detailed information about the
              suspicious activity, and the support team will assist you in
              securing your account and investigating the issue.
            </p>

           <h4>How does INRx handle data privacy?</h4>
            <p>
              INRx takes data privacy seriously and adheres to strict data
              protection regulations. Your personal information is collected,
              used, and stored in accordance with our Privacy Policy, which
              outlines how we protect your data and your rights as a user.
            </p>

           <h4>
              Can INRx be used for cross-border transactions?
            </h4>
            <p>
              Yes, INRx is ideal for cross-border transactions. Its stable value
              and low transaction fees make it a convenient option for sending
              money internationally. Ensure that both the sender and recipient
              have wallets that support INRx.
            </p>

           <h4>
              What is the process for redeeming INRx for INR?
            </h4>
            <p>
              To redeem INRx for INR, you can use supported exchanges or the
              INRx platform. Initiate a redemption request, specify the amount,
              and provide the necessary details. The INR equivalent will be
              transferred to your bank account.
            </p>

           <h4>Does INRx publish reserve reports?</h4>
            <p>
              Yes, INRx publishes regular reserve reports to ensure transparency
              and trust. These reports detail the assets held in reserve to back
              the INRx tokens in circulation, providing users with confidence in
              the stability and backing of the currency.
            </p>

           <h4>How does INRx handle customer support?</h4>
            <p>
              INRx provides robust customer support through multiple channels,
              including email, phone, and live chat. Our support team is
              available to assist with any questions or issues you may encounter
              while using the platform.
            </p>
          </div>
        </div>
      </div>{" "}
      <div>
        <Footer />
      </div>
    </>
  );
}
