import React from 'react';
import { useState, useEffect } from 'react';
import { Header } from './Header'
import "../css/Staking.css"
import Accordion_fc from './Accordion'
import { faqs } from './Accordion';
import { useDispatch } from 'react-redux';
import { setData, setupate } from '../redux/reducer/user';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

export default function Staking() {

  const dispatch = useDispatch();
  const [active, setActive] = useState("All");
  const [send, setSend] = useState("24H");


  function tabActive(act) {
    setActive(act);
    const cards = faqs.filter((element) => element.Name === act)
    dispatch(setData({ data: cards }))
    return cards;
  }
  function tab() {
    setActive("All");
    dispatch(setupate({ update: faqs }))
  }
  useEffect(() => {
    tab();
  }, [])

  return (
    <>
    {/* <ReactTooltip place="top" type="dark" effect="float"/> */}
      <section >
        <div className='tron-usdd-wrapper bg_psm'>
          <Header />
          <div className='container'>
            <div className='d-flex justify-content-center pb-2 '>
              <div className='border-rounded'>
                <div className='p-1 card-bg '>
                  <ul className="nav nav-pills text-color">
                    <li className="nav-item" onClick={() => {
                      // dispatch(setupate({update:faqs}))
                      // tabActive("All")
                      tab()

                    }
                    }>
                      <a className={active === "All" ? "nav-link active" : "nav-link"} aria-current="page">&nbsp;All &nbsp;</a>
                    </li>
                    <li className="nav-item" onClick={() => tabActive("Analog")}>
                      <a className={active === "Analog" ? "nav-link active" : "nav-link"}>Analog</a>
                    </li>
                    <li className="nav-item" onClick={() => tabActive("Ethereum")}>
                      <a className={active === "Ethereum" ? "nav-link active" : "nav-link"}>Ethereum</a>
                    </li>
                    <li className="nav-item" onClick={() => tabActive("Binance")}>
                      <a className={active === "Binance" ? "nav-link active" : "nav-link"}>BSC</a>
                    </li>
                    <li className="nav-item" onClick={() => tabActive("Polygon")}>
                      <a className={active === "Polygon" ? "nav-link active" : "nav-link"} >Polygon</a>
                    </li>
                    {/* <li className="nav-item" onClick={() => tabActive("Tron")}>
                      <a className={active === "Tron" ? "nav-link active" : "nav-link"}>Tron</a>
                    </li> */}
                  </ul>
                </div>

              </div>
            </div>

            <div className='head-border mb-3 '>
              <div className='card-bg p-3 mobile-scroll'>
                <div className='d-flex justify-content-between'>
                  <div className=''>
                    <div className='text-lg text-white font-medium font-text'>INRx Farms</div>
                    <div className='text-base text-color'>Stake LP tokens and earn token rewards</div>
                  </div>
                  <div className='d-flex align-items-center ms-3'>
                    <div className='me-2'>
                      <div class="dropdown">
                        <button class="btn btn-secondary1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          Time Basis: {send}
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li><a class="dropdown-item" onClick={()=>{setSend("24H")}} >24H</a></li>
                          <li><a class="dropdown-item" onClick={()=>{setSend("7D")}}>7D</a></li>
                          <li><a class="dropdown-item" onClick={()=>{setSend("30D")}}>30D</a></li>
                        </ul>
                      </div>
                    </div>
                    <div>
                      <input class="input-transparent" type="text" placeholder="Search" />
                    </div>
                  </div>
                </div>
                <div className='pt-2 m-1'>
                  <div className='row pt-2 pb-2 text-head bg-head'>
                    <div className='col-1'></div>
                    <div className='col'>Farm</div>
                    <div className='col'>Pending Reward</div>
                    <div className='col d-flex align-items-center'>Total APR {send} <div className='ms-1' style={{cursor: "pointer"}} data-tip=""><AiOutlineQuestionCircle/></div></div>
                    <div className='col'>TVL</div>
                    <div className='col'>ChainName</div>
                    <div className='col-1'></div>
                  </div>
                </div>
                <div>
                  <Accordion_fc cards={tabActive} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
