import React from "react";
import Footer from "./Footer";
import { Header } from "./Header";

export default function Privacy_policy() {
  return (
    <>
      <div className="tron-usdd-wrapper bg_psm content_style">
        <Header />
        <div className="page-content">
          <div className="container section-container--">
            <div className="spacer pt-0">
              <h1 class="heading">
                Privacy <span className="span-gradient"> Policy</span>
              </h1>
              <p className="text-gray">
                At INRx, we are committed to protecting your privacy and
                ensuring that your personal information is handled with care and
                respect. This Privacy Policy outlines our data practices and
                your rights as a user. We encourage you to review this policy
                regularly and contact us with any questions or concerns.
              </p>
            </div>

            <h2>1. Information We Collect</h2>
            <h3>1.1 Personal Information</h3>
            <p>We may collect the following personal information from you:</p>
            <ul className="list">
              <li>
                <strong>Identification Information:</strong> Your name, date of
                birth, and government-issued identification numbers (e.g.,
                Aadhaar, PAN).
              </li>
              <li>
                <strong>Contact Information:</strong> Your address, email
                address, and phone number.
              </li>
              <li>
                <strong>Financial Information:</strong> Bank account details,
                transaction history, and cryptocurrency wallet addresses.
              </li>
              <li>
                <strong>Verification Information:</strong> Copies of
                identification documents, photographs, and biometric data (if
                applicable).
              </li>
            </ul>

            <h3>1.2 Usage Data</h3>
            <p>
              We automatically collect information about your interactions with
              our services, including:
            </p>
            <ul className="list">
              <li>
                <strong>Device Information:</strong> IP address, browser type,
                operating system, device identifiers.
              </li>
              <li>
                <strong>Log Information:</strong> Access times, pages viewed,
                and the page you visited before navigating to our services.
              </li>
              <li>
                <strong>Location Information:</strong> Geolocation data to
                comply with regulatory requirements and improve service
                delivery.
              </li>
            </ul>

            <h3>1.3 Cookies and Tracking Technologies</h3>
            <p>
              We use cookies, web beacons, and other tracking technologies to:
            </p>
            <ul className="list">
              <li>
                <strong>Enhance User Experience:</strong> Remember your
                preferences and settings.
              </li>
              <li>
                <strong>Analyze Traffic:</strong> Monitor and analyze usage and
                performance of our services.
              </li>
              <li>
                <strong>Advertise:</strong> Deliver relevant advertisements and
                track the effectiveness of our marketing campaigns.
              </li>
            </ul>

            <h2>2. How We Use Your Information</h2>
            <h3>2.1 Providing and Improving Services</h3>
            <p>We use your personal information to:</p>
            <ul className="list">
              <li>
                <strong>Facilitate Transactions:</strong> Process transactions,
                verify your identity, and maintain transaction records.
              </li>
              <li>
                <strong>Enhance Security:</strong> Detect and prevent fraudulent
                activities, and enforce our terms and conditions.
              </li>
              <li>
                <strong>Improve Services:</strong> Conduct research and analysis
                to improve and personalize our services.
              </li>
            </ul>

            <h3>2.2 Communication</h3>
            <p>We may use your contact information to:</p>
            <ul className="list">
              <li>
                <strong>Inform You:</strong> Send transactional notifications,
                updates, and security alerts.
              </li>
              <li>
                <strong>Marketing:</strong> Provide promotional offers, surveys,
                and newsletters (you may opt-out at any time).
              </li>
            </ul>

            <h3>2.3 Legal and Compliance</h3>
            <p>We use your information to:</p>
            <ul className="list">
              <li>
                <strong>Comply with Laws:</strong> Meet regulatory requirements
                and respond to lawful requests from authorities.
              </li>
              <li>
                <strong>Enforce Agreements:</strong> Enforce our terms and
                conditions, and protect our rights and interests.
              </li>
            </ul>

            <h2>3. How We Share Your Information</h2>
            <h3>3.1 With Your Consent</h3>
            <p>
              We may share your personal information with third parties if you
              have given us your explicit consent.
            </p>

            <h3>3.2 Service Providers</h3>
            <p>We engage trusted third-party service providers to:</p>
            <ul className="list">
              <li>
                <strong>Process Transactions:</strong> Facilitate payment
                processing and other financial transactions.
              </li>
              <li>
                <strong>Perform Services:</strong> Provide IT and customer
                support services.
              </li>
              <li>
                <strong>Analyze Data:</strong> Conduct analytics and research to
                improve our services.
              </li>
            </ul>

            <h3>3.3 Legal Requirements</h3>
            <p>
              We may disclose your personal information if required by law or in
              response to:
            </p>
            <ul className="list">
              <li>
                <strong>Legal Process:</strong> Subpoenas, court orders, or
                other legal proceedings.
              </li>
              <li>
                <strong>Law Enforcement:</strong> Requests from law enforcement
                agencies or regulatory bodies.
              </li>
            </ul>

            <h3>3.4 Business Transfers</h3>
            <p>
              In the event of a merger, acquisition, or sale of assets, your
              personal information may be transferred to the acquiring entity.
            </p>

            <h2>4. How We Protect Your Information</h2>
            <h3>4.1 Security Measures</h3>
            <p>
              We implement robust security measures to protect your personal
              information, including:
            </p>
            <ul className="list">
              <li>
                <strong>Encryption:</strong> Using encryption technologies to
                secure sensitive data.
              </li>
              <li>
                <strong>Access Controls:</strong> Restricting access to personal
                information to authorized personnel only.
              </li>
              <li>
                <strong>Monitoring:</strong> Regularly monitoring our systems
                for vulnerabilities and breaches.
              </li>
            </ul>

            <h3>4.2 Data Retention</h3>
            <p>
              We retain your personal information for as long as necessary to:
            </p>
            <ul className="list">
              <li>
                <strong>Fulfill Services:</strong> Provide and improve our
                services.
              </li>
              <li>
                <strong>Comply with Legal Obligations:</strong> Meet regulatory
                and compliance requirements.
              </li>
              <li>
                <strong>Resolve Disputes:</strong> Resolve any disputes or
                enforce our agreements.
              </li>
            </ul>

            <h3>4.3 Data Minimization</h3>
            <p>
              We strive to collect and retain only the personal information that
              is necessary for the purposes outlined in this Privacy Policy.
            </p>

            <h2>5. Your Rights and Choices</h2>
            <h3>5.1 Access and Correction</h3>
            <p>You have the right to:</p>
            <ul className="list">
              <li>
                <strong>Access Your Information:</strong> Request a copy of the
                personal information we hold about you.
              </li>
              <li>
                <strong>Correct Your Information:</strong> Request corrections
                to inaccurate or incomplete personal information.
              </li>
            </ul>

            <h3>5.2 Data Portability</h3>
            <p>
              You have the right to request a copy of your personal information
              in a structured, commonly used, and machine-readable format.
            </p>

            <h3>5.3 Deletion</h3>
            <p>
              You have the right to request the deletion of your personal
              information, subject to certain exceptions, such as:
            </p>
            <ul className="list">
              <li>
                <strong>Legal Obligations:</strong> Retaining information for
                regulatory compliance.
              </li>
              <li>
                <strong>Dispute Resolution:</strong> Retaining information
                necessary to resolve disputes or enforce agreements.
              </li>
            </ul>

            <h3>5.4 Opt-Out</h3>
            <p>You have the right to:</p>
            <ul className="list">
              <li>
                <strong>Marketing Communications:</strong> Opt-out of receiving
                marketing communications from us.
              </li>
              <li>
                <strong>Cookies:</strong> Manage your cookie preferences through
                your browser settings.
              </li>
            </ul>

            <h2>6. International Data Transfers</h2>
            <h3>6.1 Transfer Mechanisms</h3>
            <p>
              We may transfer your personal information to countries outside of
              your jurisdiction, including to:
            </p>
            <ul className="list">
              <li>
                <strong>Service Providers:</strong> Trusted third-party service
                providers located in different countries.
              </li>
              <li>
                <strong>Business Partners:</strong> International partners for
                the provision of our services.
              </li>
            </ul>

            <h3>6.2 Safeguards</h3>
            <p>
              We implement appropriate safeguards to ensure the protection of
              your personal information during international transfers,
              including:
            </p>
            <ul className="list">
              <li>
                <strong>Contractual Clauses:</strong> Standard contractual
                clauses approved by regulatory authorities.
              </li>
              <li>
                <strong>Adequacy Decisions:</strong> Transfers to countries with
                adequate data protection laws.
              </li>
            </ul>

            <h2>7. Children's Privacy</h2>
            <h3>7.1 Age Restrictions</h3>
            <p>
              Our services are not intended for individuals under the age of 18.
              We do not knowingly collect personal information from children
              under 18.
            </p>

            <h3>7.2 Parental Consent</h3>
            <p>
              If we become aware that we have collected personal information
              from a child under 18 without parental consent, we will take steps
              to delete such information.
            </p>

            <h2>8. Changes to This Privacy Policy</h2>
            <h3>8.1 Updates</h3>
            <p>
              We may update this Privacy Policy from time to time to reflect
              changes in our practices, legal requirements, or other factors.
              The updated policy will be posted on our website, and the
              effective date will be indicated.
            </p>

            <h3>8.2 Notification</h3>
            <p>
              We will notify you of any material changes to this Privacy Policy
              through our services or by other means, such as email.
            </p>

            <h2>9. Contact Us</h2>
            <h3>9.1 Questions and Concerns</h3>
            <p>
              If you have any questions or concerns about this Privacy Policy or
              our data practices, please contact us at:
            </p>
            <p>
              <strong>Email:</strong> privacy@inrx.io
              <br />
              <strong>Address:</strong> INRx Privacy Office, [Address], India
            </p>

            <h3>9.2 Data Protection Officer</h3>
            <p>
              Our Data Protection Officer is responsible for overseeing our
              privacy practices and ensuring compliance with this Privacy
              Policy. You can contact the Data Protection Officer at
              dpo@inrx.io.
            </p>

            <h2>10. Regulatory Compliance</h2>
            <h3>10.1 Applicable Laws</h3>
            <p>
              We comply with applicable data protection laws and regulations,
              including:
            </p>
            <ul className="list">
              <li>
                <strong>Indian Data Protection Laws:</strong> Information
                Technology Act, 2000 and its amendments.
              </li>
              <li>
                <strong>Global Standards:</strong> General Data Protection
                Regulation (GDPR) and other relevant international data
                protection frameworks.
              </li>
            </ul>

            <h3>10.2 Data Breach Notification</h3>
            <p>In the event of a data breach, we will:</p>
            <ul className="list">
              <li>
                <strong>Investigate:</strong> Promptly investigate and assess
                the scope and impact of the breach.
              </li>
              <li>
                <strong>Notify Authorities:</strong> Notify relevant regulatory
                authorities as required by law.
              </li>
              <li>
                <strong>Inform Affected Individuals:</strong> Inform affected
                individuals if the breach is likely to result in a high risk to
                their rights and freedoms.
              </li>
            </ul>

            <h2>11. Third-Party Links</h2>
            <h3>11.1 External Websites</h3>
            <p>
              Our services may contain links to third-party websites. We are not
              responsible for the privacy practices or content of these external
              sites. We encourage you to review the privacy policies of any
              third-party sites you visit.
            </p>

            <h3>11.2 Social Media</h3>
            <p>
              Our services may include social media features and widgets. These
              features may collect information about your interactions and may
              be hosted by third parties. Your interactions with these features
              are governed by the privacy policies of the respective social
              media platforms.
            </p>

            <h2>12. User Responsibilities</h2>
            <h3>12.1 Maintaining Security</h3>
            <p>
              You are responsible for maintaining the security of your account
              credentials and ensuring that your personal information is
              accurate and up to date.
            </p>

            <h3>12.2 Reporting Issues</h3>
            <p>
              If you suspect any unauthorized use of your account or any other
              security breach, you must promptly notify us at security@inrx.io.
            </p>

            <h2>13. Consent</h2>
            <h3>13.1 Acceptance</h3>
            <p>
              By using our services, you consent to the collection, use, and
              disclosure of your personal information as described in this
              Privacy Policy.
            </p>

            <h3>13.2 Withdrawal of Consent</h3>
            <p>
              You have the right to withdraw your consent at any time. However,
              withdrawing consent may limit your ability to use certain features
              of our services.
            </p>
          </div>
        </div>
      </div>{" "}
      <div>
        <Footer />
      </div>
    </>
  );
}
