import {useEffect,useState} from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';

export function useNetwork(){
    const [CurrentINRxPrice, SetCurrentINRxPrice] = useState(0);
    // const [network ,setNetwork] = useState(null);
    const params = useParams();
   const network= params.network

    // const networks = params.network
    useEffect(()=>{
        axios
      .get(`https://min-api.cryptocompare.com/data/price?fsym=USDT&tsyms=INR`)
      .then((res) => {
        const orderrespons = res.data.INR;
        SetCurrentINRxPrice(orderrespons);
      })
      .catch((error) => {
        // console.log(error.message);
      }, []);

        // setNetwork(params.network)
        // console.log(network,"NETWORK:::::1:")
    },[])
    console.log(network,CurrentINRxPrice,"prices")
    return [network,CurrentINRxPrice];
}