import React, { useEffect, useState } from "react";
import { Header } from "../Header";
// import "../../css/AboutUS.css";
import { IoCopyOutline } from "react-icons/io5";
import { RiShareBoxFill } from "react-icons/ri";
import copy from "copy-to-clipboard";

import { Link } from "react-router-dom";
import Footer from "../Footer";
import Table from "../Table";
import { MdArrowRightAlt } from "react-icons/md";
import {
  getBscTotalSupply,
  getETHTotalSupply,
  getPolygonTotalSupply,
} from "../../web3/web3Helper";
import { getAllReports, url } from "../api_function";
export default function TrancparencyPage() {
  const [value, setValue] = React.useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [activeTab1, setActiveTab1] = useState(0);
  const [bscSupply, setBscSupply] = useState({});
  const [polygonSupply, setPolygonSupply] = useState({});
  const [apiReport, setApiReport] = useState([]);
  const [ethSupply, setETHSupply] = useState([]);
  const [copiedIndex, setCopiedIndex] = useState(null);

  const handleCopy = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedIndex(index);
      setTimeout(() => setCopiedIndex(null), 2000); // Reset the copied state after 2 seconds
    });
  };

  const assets = [
    { amount: "0 INRx", address: "YUUhgSb562.....HJK56d" },
    { amount: "13,445.321 INRx", address: "SUUhgSb562.....HJK56899" },
  ];

  const otherAssets = [
    {
      amount: "100,457.856 INRx",
      address: "ADChgSb562.....HJK5S6",
      blockchain: "0KC- KIP20",
    },
    {
      amount: "113,457.258 INRx",
      address: "PPUUhgSb562.....HJK5ss6d",
      blockchain: "0KC- KIP20",
    },
  ];

  useEffect(() => {
    const getContract = async () => {
      try {
        const bscres = await getBscTotalSupply();
        const polygonres = await getPolygonTotalSupply();
        const ethRes = await getETHTotalSupply();
        const res = await getAllReports();
        console.log(res,"res")
        setApiReport(res?.data?.data);
        setBscSupply(bscres);
        setPolygonSupply(polygonres);
        setETHSupply(ethRes);

      } catch (error) {
        console.log(error);
      }
    };
    getContract();
  }, []);
  return (
    <>
      <Header />
      <div className="container">
        <div className="col-lg-12">
          <div className="usdd-exchange">
            <div className="usdd-cont">
              <div className="title">Transparency</div>
              <div className="desc mb-4">
                All INRx tokens are fully backed by INRx's reserves and are tied
                at a 1:1 ratio with corresponding fiat currencies. At least once
                a day, the value of our reserves is updated and made public.
              </div>
              <img className="exchangeRight" src="/img/balance_1.gif" alt="" />
              <img
                className="exchangeRight"
                src="/img/exchange-bg.png"
                alt=""
              />
            </div>
          </div>

          <div className="row spacer">
            <div class="nav nav-tabs justify-content-center spacer">
              <a
                class="nav-link active"
                className={`nav-link ${
                  activeTab === 0 ? "active activeT" : ""
                }`}
                onClick={() => setActiveTab(0)}
              >
                Current Balance
              </a>
              <a
                className={`nav-link ${
                  activeTab === 1 ? "active activeT" : ""
                }`}
                onClick={() => setActiveTab(1)}
              >
                Report & Reserves
              </a>
              <a
                className={`nav-link ${
                  activeTab === 2 ? "active activeT" : ""
                }`}
                onClick={() => setActiveTab(2)}
              >
                Bridges
              </a>
            </div>

            <div className="tab-content">
              {activeTab === 0 && (
                <div className="spacer">
                  <h3 className="heading  mb-2 text-center">Current Balance</h3>
                  <h4 className="text-center text-gray fs-15 mb-4">
                    <span className="">Last Update: </span> Jan 15, 2024 -
                    11:30:10 PM UTC
                  </h4>
                  <p className="text-gray text-center mb-3 px-3 mt-4">
                    At INRx, we recognize the importance of adapting to industry
                    changes and leveraging these shifts to enhance our
                    offerings. The INRx Foundation's decision to phase out
                    support for these implementations aligns with our mission to
                    remain at the forefront of decentralized finance (DeFi)
                    innovation. Here’s how this transition impacts and benefits
                    INRx:
                  </p>

                  <div className="spacer">
                    <div className="row justify-content-center">
                      <div className="col-lg-4">
                        <div className="">
                          <div className="custom_card">
                            <div className="">
                              <div className="d-flex justify-content-between mb-2">
                                <div>
                                  <h4 className="h5">Ethereum</h4>
                                </div>
                                <Link className="btn-hover color-8 py-1 px-3 fs-13">
                                  Details
                                </Link>
                              </div>
                              <div className="d-flex justify-content-between mb-1">
                                <div className="text-gray">
                                  {" "}
                                  Total Authorized
                                </div>
                                <div> {ethSupply?.TotalSupply &&
                                    (
                                      ethSupply?.TotalSupply -
                                      ethSupply?.OwnerBalance
                                    ).toFixed(2)}</div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div className="text-gray">
                                  {" "}
                                  Less: Authorized but not issued
                                </div>
                                <div> {ethSupply?.TotalSupply &&
                                    (ethSupply?.TotalSupply).toFixed(2)}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="">
                          <div className="custom_card">
                            <div className="">
                              <div className="d-flex justify-content-between mb-2">
                                <div>
                                  <h4 className="h5"> On BSC</h4>
                                </div>
                                <Link className="btn-hover color-8 py-1 px-3 fs-13">
                                  Details
                                </Link>
                              </div>
                              <div className="d-flex justify-content-between mb-1">
                                <div className="text-gray">
                                  {" "}
                                  Total Authorized
                                </div>
                                <div>
                                  {bscSupply?.TotalSupply &&
                                    (
                                      bscSupply?.TotalSupply -
                                      bscSupply?.OwnerBalance
                                    ).toFixed(2)}
                                </div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div className="text-gray">
                                  {" "}
                                  Less: Authorized but not issued
                                </div>
                                <div>
                                  {" "}
                                  {bscSupply?.TotalSupply &&
                                    (bscSupply?.TotalSupply).toFixed(2)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="">
                          <div className="custom_card">
                            <div className="">
                              <div className="d-flex justify-content-between mb-2">
                                <div>
                                  <h4 className="h5"> On Polygon</h4>
                                </div>
                                <Link className="btn-hover color-8 py-1 px-3 fs-13">
                                  Details
                                </Link>
                              </div>
                              <div className="d-flex justify-content-between mb-1">
                                <div className="text-gray">
                                  {" "}
                                  Total Authorized
                                </div>
                                <div>
                                  {" "}
                                  {polygonSupply?.TotalSupply &&
                                    (
                                      polygonSupply?.TotalSupply -
                                      polygonSupply?.OwnerBalance
                                    ).toFixed(2)}
                                </div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div className="text-gray">
                                  {" "}
                                  Less: Authorized but not issued
                                </div>
                                <div>
                                  {polygonSupply.TotalSupply &&
                                    (polygonSupply?.TotalSupply).toFixed(2)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-4">
                        <div className="">
                          <div className="custom_card">
                            <div className="">
                              <div className="d-flex justify-content-between mb-2">
                                <div>
                                  <h4 className="h5"> On Tron</h4>
                                </div>
                                <Link className="btn-hover color-8 py-1 px-3 fs-13">
                                  Details
                                </Link>
                              </div>
                              <div className="d-flex justify-content-between mb-1">
                                <div className="text-gray">
                                  {" "}
                                  Total Authorized
                                </div>
                                <div> 0</div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div className="text-gray">
                                  {" "}
                                  Less: Authorized but not issued
                                </div>
                                <div> 0</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="">
                          <div className="custom_card">
                            <div className="">
                              <div className="d-flex justify-content-between mb-2">
                                <div>
                                  <h4 className="h5"> On INRx</h4>
                                </div>
                                <Link className="btn-hover color-8 py-1 px-3 fs-13">
                                  Details
                                </Link>
                              </div>
                              <div className="d-flex justify-content-between mb-1">
                                <div className="text-gray">
                                  {" "}
                                  Total Authorized
                                </div>
                                <div> 0</div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div className="text-gray">
                                  {" "}
                                  Less: Authorized but not issued
                                </div>
                                <div> 0</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div className="above-footer">
                        <div className="row">
                          <div className="col-lg-6 m-auto">
                            <div className="row text-center">
                              <div className="col-lg-6 mb-3">
                                <h2>
                                  {" "}
                                  {( polygonSupply?.TotalSupply + bscSupply?.TotalSupply + ethSupply?.TotalSupply).toFixed(2)}
                                </h2>
                                <p className="text-gray">Total Assets</p>
                              </div>
                              <div className="col-lg-6 mb-3">
                                <h2> 0</h2>
                                <p className=" text-gray">Total Liabilities</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="spacer">
                          <p className="text-gray fs-14">
                            As part of our continued commitment to transparency
                            and trust, the INRx Foundation and its stakeholders
                            allocate capital in reserves, which are reviewed on
                            a quarterly basis. This liquidity cushion is
                            maintained above and beyond the total market
                            capitalization of INRx tokens (INRx) to provide
                            further stability to the INRx ecosystem and
                            assurance to our users.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 1 && (
                <div className="spacer">
                  <div class="">
                    <div class="text-center">
                      <h3 className="mb-2 heading">Reports & Reserves</h3>
                      <h4 className="text-center text-gray fs-15 mb-4">
                        Last Update: September 30, 2023 UTC
                      </h4>
                      <p className="px-3 text-gray">
                        Quarterly assurance opinions are conducted by INRx
                        Mechanism LLP as part of our ongoing commitment to
                        openness. We provide all of our previous and most
                        current thoughts here for greater accessibility.
                        Advisory performs the assurance opinions. All INRx
                        tokens are completely backed by reserves, as stated in
                        the opinion and the accompanying report from INRx, which
                        also offers a detailed analysis of those reserves..
                      </p>
                    </div>
                    <div>
                      <div class="row spacer">
                        {apiReport && apiReport?.map((item,index)=>(
                          <div className="col-lg-4 mb-4" key={index}>
                           <a href={`${url}/${(item?.file).replace("uploads","support")}`} target="_blanck">
                              <div class="preview_card">
                                <img
                                  src="/img/pdf-preview.png"
                                  class="preview-card-img-top"
                                />
                                <div className="preview_card_body">
                                  <p class="mb-2">
                                    {item?.title}
                                  </p>
                                  <h6 className="text-gray fs-13">
                                    {item?.quater}
                                  </h6>
                                </div>
                              </div>
                            </a>
                          </div>
                        ))}

                        {/* <div className="col-lg-4 mb-4">
                          <Link>
                            <div class="preview_card">
                              <img
                                src="/img/pdf-preview.png"
                                class="preview-card-img-top"
                              />
                              <div className="preview_card_body">
                                <p class="mb-2">
                                  Independent Auditors' Report on the
                                  Consolidated Reserves Report
                                </p>
                                <h6 className="text-gray fs-13">
                                  October 31, 2018
                                </h6>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="col-lg-4 mb-4">
                          <Link>
                            <div class="preview_card">
                              <img
                                src="/img/pdf-preview.png"
                                class="preview-card-img-top"
                              />
                              <div className="preview_card_body">
                                <p class="mb-2">
                                  Independent Auditors' Report on the
                                  Consolidated Reserves Report
                                </p>
                                <h6 className="text-gray fs-13">
                                  October 31, 2018
                                </h6>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="col-lg-4 mb-4">
                          <Link>
                            <div class="preview_card">
                              <img
                                src="/img/pdf-preview.png"
                                class="preview-card-img-top"
                              />
                              <div className="preview_card_body">
                                <p class="mb-2">
                                  Independent Auditors' Report on the
                                  Consolidated Reserves Report
                                </p>
                                <h6 className="text-gray fs-13">
                                  October 31, 2018
                                </h6>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="col-lg-4 mb-4">
                          <Link>
                            <div class="preview_card">
                              <img
                                src="/img/pdf-preview.png"
                                class="preview-card-img-top"
                              />
                              <div className="preview_card_body">
                                <p class="mb-2">
                                  Independent Auditors' Report on the
                                  Consolidated Reserves Report
                                </p>
                                <h6 className="text-gray fs-13">
                                  October 31, 2018
                                </h6>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="col-lg-4 mb-4">
                          <Link>
                            <div class="preview_card">
                              <img
                                src="/img/pdf-preview.png"
                                class="preview-card-img-top"
                              />
                              <div className="preview_card_body">
                                <p class="mb-2">
                                  Independent Auditors' Report on the
                                  Consolidated Reserves Report
                                </p>
                                <h6 className="text-gray fs-13">
                                  October 31, 2018
                                </h6>
                              </div>
                            </div>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 2 && (
                <div className="spacer">
                  <div className="mb-4">
                    <div className="text-center">
                      <h3 className="heading mb-2">XTRING Network</h3>
                      <h4 className="fs-15 text-gray">
                        Last Update: Jan 19, 2024 - 05:47:19 AM UTC
                      </h4>
                    </div>
                    <div className="spacer">
                      <p className="px-4 text-gray fs-15 text-center">
                        INRx has decided to provide information about INRx that
                        is "bridged" by Xtring Network utilizing the Xtring
                        Bridge. Xtring Network reports that the "Locked Assets"
                        should be slightly larger than the "Assets on other
                        blockchains" because a portion of the "Locked Assets"
                        serve as withdrawal fees. The "Locked Assets" are a
                        portion of the issued INRx on the TRON and Ethereum
                        blockchains reported elsewhere as Current Balances on
                        INRx's Transparency pages and do not represent
                        additional outstanding INRx tokens. These Locked Assets
                        also are not assets of INRx and are not a part of INRx's
                        Reserves reported elsewhere on INRx's Transparency
                        pages. The "Assets on other blockchains" are not issued
                        by or endorsed by INRx
                      </p>
                    </div>

                    <div className="spacer">
                      <div className="">
                        <div className="row justify-content-center align-items-center">
                          <div className="col-lg-5">
                            <h6 className="mb-3 h4">
                              Locked{" "}
                              <span className="span-gradient fw-bold">
                                Assets
                              </span>
                            </h6>
                            <div className="row">
                              {assets.map((asset, index) => (
                                <div key={index} className="col-md-12 mb-4">
                                  <div className="okc-bridge-col">
                                    <div>
                                      <h5 className="mb-0">{asset.amount}</h5>
                                      <span className="text-gray fs-13">
                                        {asset.address}
                                      </span>
                                    </div>
                                    <div>
                                      <div className="d-flex gap-2">
                                        <div>
                                          {copiedIndex === index && (
                                            <span className="copied_msg">
                                              Copied
                                            </span>
                                          )}
                                        </div>
                                        <div
                                          className="cursor_pointer"
                                          onClick={() =>
                                            handleCopy(asset.address, index)
                                          }
                                        >
                                          <IoCopyOutline />
                                        </div>
                                        <div>
                                          <a href="#">
                                            <RiShareBoxFill />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="col-lg-2 mb-4 text-center">
                            <MdArrowRightAlt className="fs-1 text-purple rotate-icon" />
                          </div>
                          <div className="col-lg-5">
                            <h6 className="mb-3 h4">
                              Assets on{" "}
                              <span className="span-gradient">
                                Other blockchains
                              </span>
                            </h6>
                            <div className="row">
                              {otherAssets.map((asset, index) => (
                                <div key={index} className="col-md-12 mb-4">
                                  <div className="okc-bridge-col">
                                    <div>
                                      <h5 className="mb-0">{asset.amount}</h5>
                                      <span className="text-gray fs-13">
                                        {asset.address}
                                        <span className="text-block-106">
                                          {asset.blockchain}
                                        </span>
                                      </span>
                                    </div>
                                    <div className="d-flex gap-2">
                                      <div>
                                        {copiedIndex ===
                                          index + assets.length && (
                                          <span className="copied_msg">
                                            Copied
                                          </span>
                                        )}
                                      </div>
                                      <div
                                        className="cursor_pointer"
                                        onClick={() =>
                                          handleCopy(
                                            asset.address,
                                            index + assets.length
                                          )
                                        }
                                      >
                                        <IoCopyOutline />
                                      </div>
                                      <div>
                                        <a href="#">
                                          <RiShareBoxFill />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="above-footer">
                    <div className="">
                      <div className="card-body">
                        <p className="text-muted fs-13">
                          The above information has not been reviewed by INRx
                          for consistency with the information reported by
                          Xtring Network. Xtring Chain and related reporting of
                          information is the responsibility of Xtring Network,
                          and INRx is not auditing or providing any form of
                          assurance of the information reported or the security
                          or operation of Xtring Chain, Xtring Bridge, TRON,
                          Ethereum, and bridged tokens on Xtring Chain
                          (including bridged versions of INRx). Xtring Network,
                          the Xtring Chain, the Xtring Bridge, TRON, Ethereum,
                          and bridged tokens on Xtring Chain (including bridged
                          versions of INRx) are third-party services and
                          technology within the meaning of INRx's Terms of
                          Service , and INRx does not own or control these
                          blockchain protocols or services or provide any
                          assurance of their functionality. INRx does not
                          provide advice about the use or suitability for a
                          particular user of third-party services or technology
                          and disclaims any representation or warranty, express
                          or implied, relating inrxio.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
