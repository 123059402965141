import React from "react";
import Footer from "./Footer";
import { Header } from "./Header";

export default function BlackfundPolicy() {
  return (
    <>
      <div className="tron-usdd-wrapper bg_psm content_style">
        <Header />
        <div className="page-content">
          <div className="container section-container--">
            <div className="spacer pt-0">
              <h1 class="heading">
                Blackfund <span className="span-gradient"> Policy</span>
              </h1>
              <p className="text-gray">
                The Blacklist and Blackfund Policy outlines the criteria and
                procedures for identifying and managing accounts that engage in
                fraudulent, malicious, or prohibited activities within the INRx
                ecosystem. The goal is to ensure the integrity and security of
                our platform by preventing misuse and protecting our users.
              </p>
            </div>

            <h2>1. Criteria for Blacklisting</h2>
            <h3>1.1 Fraudulent Activities</h3>
            <p>
              Accounts involved in any form of fraud, including but not limited
              to identity theft, false claims, and transaction manipulation,
              will be blacklisted.
            </p>
            <h3>1.2 Unauthorized Access</h3>
            <p>
              Any attempt to gain unauthorized access to INRx systems, user
              accounts, or confidential information will result in blacklisting.
            </p>
            <h3>1.3 Illegal Activities</h3>
            <p>
              Engaging in activities that violate local, national, or
              international laws, including money laundering and terrorist
              financing, will lead to blacklisting.
            </p>
            <h3>1.4 Abuse and Harassment</h3>
            <p>
              Accounts that harass, threaten, or abuse other users or INRx staff
              will be blacklisted.
            </p>
            <h3>1.5 Prohibited Transactions</h3>
            <p>
              Conducting transactions that violate our Terms and Conditions or
              other policies, such as engaging in high-risk trading or
              supporting blackfund activities, will result in blacklisting.
            </p>

            <h2>2. Procedures for Blacklisting</h2>
            <h3>2.1 Investigation</h3>
            <p>
              Upon suspicion or detection of prohibited activities, INRx will
              conduct a thorough investigation. This may involve reviewing
              transaction histories, communication logs, and other relevant
              data.
            </p>
            <h3>2.2 Notification</h3>
            <p>
              If an account is found to be in violation, the user will be
              notified of the blacklisting decision and the reasons for it.
              Notification will be sent to the registered email address
              associated with the account.
            </p>
            <h3>2.3 Appeal</h3>
            <p>
              Users have the right to appeal a blacklisting decision by
              submitting a written request to our support team within 30 days of
              receiving the notification. The appeal must include relevant
              evidence and a detailed explanation of the circumstances. Our team
              will review the appeal and make a final decision.
            </p>
            <h3>2.4 Final Decision</h3>
            <p>
              After reviewing the appeal, INRx will provide a final decision. If
              the appeal is successful, the account may be reinstated with or
              without conditions. If the appeal is denied, the blacklisting
              remains in effect.
            </p>

            <h2>3. Blackfund Policy</h2>
            <h3>3.1 Definition of Blackfund</h3>
            <p>
              Blackfund refers to any funds obtained through fraudulent,
              illegal, or prohibited activities. This includes but is not
              limited to:
            </p>
            <ul>
              <li>
                Funds obtained through phishing, hacking, or other cybercrimes.
              </li>
              <li>
                Funds associated with money laundering or terrorist financing.
              </li>
              <li>
                Funds involved in Ponzi schemes or other deceptive practices.
              </li>
            </ul>
            <h3>3.2 Detection and Monitoring</h3>
            <p>
              INRx employs advanced monitoring tools and techniques to detect
              suspicious transactions and blackfund activities. This includes:
            </p>
            <ul>
              <li>
                <strong>Transaction Monitoring:</strong> Analyzing transaction
                patterns for irregularities.
              </li>
              <li>
                <strong>Behavioral Analysis:</strong> Monitoring user behavior
                for signs of fraudulent activities.
              </li>
              <li>
                <strong>Third-Party Reporting:</strong> Collaborating with other
                financial institutions and regulatory bodies to identify
                blackfund activities.
              </li>
            </ul>
            <h3>3.3 Freezing and Seizure</h3>
            <p>
              If blackfund activities are detected, INRx reserves the right to:
            </p>
            <ul>
              <li>
                Freeze the affected accounts to prevent further transactions.
              </li>
              <li>
                Seize the blackfunds and hold them in a secure account pending
                investigation.
              </li>
            </ul>
            <h3>3.4 Reporting to Authorities</h3>
            <p>
              In cases of significant blackfund activities, INRx will report the
              findings to relevant regulatory authorities and law enforcement
              agencies. This may include sharing transaction details, user
              information, and other pertinent data.
            </p>
            <h3>3.5 Restitution</h3>
            <p>
              If blackfunds are recovered, INRx will take appropriate steps to
              return the funds to their rightful owners or victims, where
              possible. This process will be carried out in accordance with
              legal and regulatory requirements.
            </p>

            <h2>4. User Responsibilities</h2>
            <h3>4.1 Due Diligence</h3>
            <p>
              Users are responsible for conducting due diligence before engaging
              in transactions or activities on the INRx platform. This includes
              verifying the legitimacy of counterparties and ensuring compliance
              with our Terms and Conditions.
            </p>
            <h3>4.2 Reporting Suspicious Activities</h3>
            <p>
              Users are encouraged to report any suspicious activities or
              potential violations of this policy to our support team
              immediately. Reports can be made anonymously, and INRx will take
              all necessary steps to investigate and address the issues.
            </p>

            <h2>5. Amendments to the Policy</h2>
            <p>
              INRx reserves the right to amend this Blacklist and Blackfund
              Policy at any time to reflect changes in our practices or legal
              requirements. The updated policy will be posted on our website,
              and the effective date will be indicated. Your continued use of
              our services after the effective date constitutes your acceptance
              of the updated policy.
            </p>

            <hr />
            <h2>6. Contact Information</h2>
            <p>
              For any questions or concerns regarding this policy, please
              contact us at:
            </p>
            <p>
              <strong>Email:</strong> security@inrx.io
              <br />
              <strong>Address:</strong> INRx Security Office, [Address], India
            </p>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}
