import axios from "axios";

export const url = "https://backoffice.inrx.io/api";
export const url2 = "https://backoffice.inrx.io/api";

// export const url = "http://localhost:2222/api";

export const getAllReports = async()=>{
    try {
        const res = await axios.get(`${url}/admin/all-reports`)
        return res;
    } catch (error) {
        console.log(error)
    }
}