import React from "react";
import { FiMail } from "react-icons/fi";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { Link } from "react-router-dom";
import { FaTelegramPlane } from "react-icons/fa";
import { FiTwitter } from "react-icons/fi";
import { AiFillYoutube } from "react-icons/ai";
export default function Footer() {
  return (
    <>
      {/* <hr /> */}
      <div className="footer text-center py-4">
        <div>
          <div className="d-flex justify-content-center align-items-center">
            <div className="me-1">
              <a
                className="m-1"
                href="https://twitter.com/inrxfoundation"
                target="_blank"
              >
                <span className="nav-icon text-light fs-5 pe-2 ">
                  <FiTwitter />
                </span>
              </a>
            </div>

            <div className=" me-1">
              <a
                className="m-1"
                href="https://t.me/inrxfoundation"
                target="_blank"
              >
                <span className="nav-icon text-light fs-5 pe-2">
                  <FaTelegramPlane />
                </span>
              </a>
            </div>
            <div className="me-1">
              <a
                className="m-1"
                href="https://www.youtube.com/playlist?list=PLHfr7s7NVZughpYr3k0QNlyply7CzV1Qm"
                target="_blank"
              >
                <span className="nav-icon text-light fs-5">
                  <AiFillYoutube />
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <p className="my-2 fs-13">
            <a href="mailto:support@inrx.io" target="_blank">
              <FiMail /> support@inrx.io
            </a>
          </p>
        </div>

        <div className="col-lg-12">
          <div className="flex_footer mb-2">
            <div className="">
              <div className="">
                <Link to="/faq" target="_blank">
                  FAQs
                </Link>
              </div>
            </div>
            <div className="">
              <div className="">
                <Link to="/blackfundpolicy" target="_blank">
                  Blackfund Policy
                </Link>
              </div>
            </div>
            <div className="">
              <div className="">
                <Link to="/privacy-policy" target="_blank">
                  Privacy & Policy
                </Link>
              </div>
            </div>
            <div className="">
              <div className="">
                <Link to="/terms-and-conditions" target="_blank">
                  Terms & Conditions
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div>
          <p className="fs-12 text-gray">
            Copyright &copy; {new Date().getFullYear()} INRx FOUNDATION. All
            Rights Reserved
          </p>
        </div>
      </div>
    </>
  );
}
