import React, { useEffect, useState } from "react";
import "../css/Header.css";
import { BrowserRouter as Router, Link, Route, Switch, NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import { onConnect, onConnectTron } from "../helpers/getWeb3";
import { useSelector, useDispatch } from "react-redux";
// import { setConfig, setTronConfig } from "../redux/reducer/user";
import { useNetwork } from "../helpers/useNetwork";
import { HiMenu } from "react-icons/hi";
import { setAccountStatus } from "../redux/reducer/user";
import "../css/wagmi.css";
import { AiFillCiCircle } from "react-icons/ai";
import { HiBars3BottomRight, HiMiniBars3BottomRight } from "react-icons/hi2";
// import { ConnectButton } from "@rainbow-me/rainbowkit";
export const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { allData } = useSelector((state) => state.user.value);
  const netw = useNetwork();
  const networks = netw[0];
  const [show, setShow] = useState(false);

  const handleChange = () => {
    setShow(!show);
  };

  // const handleResize = () => {
  //   if (window.innerWidth <= 390) {
  //     setShow(false);
  //   }
  // }
  useEffect(() => {
    const handleBodyClick = (event) => {
      if (show && !event.target.closest(".mob_nav")) {
        setShow(false);
      }
    };
    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, [show]);

  const network_USDT = {
    "make-a-voting": (allData?.ana * allData?.usdt_price)?.toFixed(2),
    voting: (allData?.ana * allData?.usdt_price)?.toFixed(2),
    proposal: (allData?.ana * allData?.usdt_price)?.toFixed(2),
    analog: (allData?.ana * allData?.usdt_price)?.toFixed(2),
    ethereum: (allData?.eth * allData?.usdt_price)?.toFixed(2),
    bsc: (allData?.bnb * allData?.usdt_price)?.toFixed(2),
    polygon: (allData?.matic * allData?.usdt_price)?.toFixed(2),
  };
  const network_INRx = {
    "make-a-voting": allData?.Tsupply_ana?.toFixed(2),
    voting: allData?.Tsupply_ana?.toFixed(2),
    proposal: allData?.Tsupply_ana?.toFixed(2),
    analog: allData?.Tsupply_ana?.toFixed(2),
    ethereum: allData?.Tsupply_eth?.toFixed(2),
    bsc: allData?.Tsupply_bnb?.toFixed(2),
    polygon: allData?.Tsupply_matic?.toFixed(2),
  };
  const network_Ratio = {
    "make-a-voting": (
      (allData?.Tsupply_ana / allData?.ana) *
      allData?.usdt_price
    )?.toFixed(2),
    voting: (
      (allData?.Tsupply_ana / allData?.ana) *
      allData?.usdt_price
    )?.toFixed(2),
    proposal: (
      (allData?.Tsupply_ana / allData?.ana) *
      allData?.usdt_price
    )?.toFixed(2),
    analog: (
      (allData?.Tsupply_ana / allData?.ana) *
      allData?.usdt_price
    )?.toFixed(2),
    ethereum: (
      (allData?.Tsupply_eth / allData?.eth) *
      allData?.usdt_price
    )?.toFixed(2),
    bsc: ((allData?.Tsupply_bnb / allData?.bnb) * allData?.usdt_price)?.toFixed(
      2
    ),
    polygon: (
      (allData?.Tsupply_matic / allData?.matic) *
      allData?.usdt_price
    )?.toFixed(2),
  };
  const head_USDT = network_USDT[networks];
  const head_INRx = network_INRx[networks];
  const head_Ratio = network_Ratio[networks];

  useEffect(() => {
    const nav = document.getElementsByClassName("mynav")[0];
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 70) {
        nav.classList.add("my-sticky");
      } else {
        nav.classList.remove("my-sticky");
      }
    });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          {/* <div className="data-info" id="HeaderTop">
            <span className="value d-flex totalNumber set-margin-m">
              <img
                src="/img/crypto_set.png"
                alt=""
                style={{ height: "25px" }}
                className="pb-1 ps-2 pt-1 mx-2"
              />{" "}
              {!networks
                ? (allData?.bnb * allData?.usdt_price)?.toFixed(2)
                : head_USDT}

            </span>
            <span className="home_backing">
              <span className="label">backing</span>
              <span className="value set-margin-m">
                {!networks ? allData?.Tsupply_bnb?.toFixed(2) : head_INRx}

                INR
                <span style={{ color: "#03e804" }}>x</span>
              </span>
            </span>
            <span className="home_backing">
              <span className="label">Collat. Ratio</span>
              <span className="value mr0" style={{ color: "red" }}>
                {!networks
                  ? (
                      (allData?.Tsupply_bnb / allData?.bnb) *
                      allData?.usdt_price
                    )?.toFixed(2)
                  : head_Ratio}

                %
              </span>
            </span>
          </div> */}
        </div>
      </div>

      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light  mynav">
          <div className="nav-set-bar-- container-fluid">
            <Link className="navbar-brand text-light header-left" to="/">
              <img src="/img/logo/white@72x.png" alt="INRx Stable Coin powers stable transactions on the INRx Blockchain Network." />
              <div className="logo-right ">
                <div className="fs-4 fw-bold" style={{ lineHeight: "0" }}>
                  INR
                  <span
                    className="fs-4 fw-bold"
                    style={{ color: "#03e804", marginLeft: "2px" }}
                  >
                    x
                  </span>
                </div>
                <span className="logo_subtext">Decentralized INR</span>
              </div>
            </Link>
            <button
              className="navbar-toggler px-0"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <span className="navbar-toggler-icon px-0">
                <HiMiniBars3BottomRight className="text-white fs-4" />
              </span>
            </button>

            <div
              class="offcanvas offcanvas-start mobile_offcanvas_body"
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasExampleLabel"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                  <img src="/img/logo/white@72x.png" alt="logo" width="50px" />
                </h5>
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>

              <div className="offcanvas-body">
                <ul className="navbar-nav mb-2 mb-lg-0 m-auto">
                  <li className="nav-item head-bottom-border "  data-bs-dismiss="offcanvas">
                  <NavLink  className={({ isActive }) => (isActive ? "nav-link11 active" : "nav-link11")} to="/about">
                      About Us
                    </NavLink>
                  </li>
                  <li className="nav-item head-bottom-border"  data-bs-dismiss="offcanvas">
                    <NavLink className={({ isActive }) => (isActive ? "nav-link11 active" : "nav-link11")}  to="/transparency">
                      Transparency
                    </NavLink>
                  </li>
                  <li className="nav-item head-bottom-border"  data-bs-dismiss="offcanvas">
                    <NavLink className={({ isActive }) => (isActive ? "nav-link11 active" : "nav-link11")}  to="/FeeDetails">
                      Fee Details
                    </NavLink>
                  </li>
                  <li className="nav-item head-bottom-border"  data-bs-dismiss="offcanvas">
                    <NavLink className={({ isActive }) => (isActive ? "nav-link11 active" : "nav-link11")}  to="/careers">
                      Career
                    </NavLink>
                  </li>
                  <li className="nav-item head-bottom-border"  data-bs-dismiss="offcanvas">
                    <NavLink className={({ isActive }) => (isActive ? "nav-link11 active" : "nav-link11")}  to="/migration">
                      Migration
                    </NavLink>
                  </li>

                  {/* {location.pathname === "/" || location.pathname === "/about" || location.pathname === "/privacy-policy" || location.pathname === "/careers" ? */}
                </ul>
                <div className="mob_nav">
                  <div className="d-grid">
                    <Link
                      className="btn-hover color-4 py-2 px-4 fs-14"
                      aria-current="page"
                      to="https://auth.inrx.io/"
                    >
                      Login
                    </Link>
                  </div>

                  <div className="d-grid">
                    <a
                      className="btn-hover color-8 py-2 px-4 fs-14"
                      target="_blank"
                      aria-current="page"
                      href="/assets/CertiK-Audit-for-INRx---Audit-v9.pdf"
                    >
                      Audit Report
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};
