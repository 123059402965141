import React, { useEffect, useState } from "react";
import { Header } from "./Header";
import Footer from "./Footer";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import toast, { Toaster } from 'react-hot-toast';
import { useAccount } from "wagmi";
import { approveContract, getBalanceUser, getTokenAllowance, newInrx, swapToken, TOKEN_MIGRATION_ADDRESS } from "../helpers/getWeb3";

export default function Tron() {
  const [val, setVal] = useState(0); // Amount to migrate
  const { address } = useAccount();
  const [bal ,setBal] = useState(0);
  const [newBal ,setNewBal] = useState(0);

  // Function to handle token migration
  async function migrateToken() {
    var amtt = val;
    amtt = (amtt*1e2).toLocaleString("fullwide", { useGrouping: false })

    if (!amtt || amtt <= 0) {
      console.error("Invalid amount");
      return;
    }


    try {
      const allowance = await getTokenAllowance(address);

      console.log(allowance, amtt, address, "adadadsadadsadasdassdadsadssd");
      if (Number(allowance) >= Number(amtt)) {
        const swapResult =  swapToken(amtt);
       await toast.promise(swapResult, {
          loading: "Swap is pending",
          success: "Swap Successful 👌",
          error: "Error",
        });
        console.log("Swap result:", swapResult);
      } else {

        const ress =  approveContract(amtt);
       await  toast.promise(ress, {
          loading: "Approve is pending",
          success: "Approve Successful 👌",
          error: "Error",
        });
        if(ress){
          const  swapResult =  swapToken(amtt);
          await toast.promise(swapResult, {
            loading: "Swap is pending",
            success: "Swap Successful 👌",
            error: "Error",
          });
          console.log("Swap result:", swapResult);
        }
       
      }
    } catch (error) {
      console.error("Error during migration:", error);
    }
  }

 async function handleClick(){

   const  bal = await getBalanceUser(address,TOKEN_MIGRATION_ADDRESS);
   console.log(bal,bal);
   setVal(bal);

  }
  

  useEffect(()=>{
     getBalanceUser(address,TOKEN_MIGRATION_ADDRESS).then((res)=>{
      setBal(res); 
   }).catch((err)=>{
      console.log(err);
   })
 
  },[address])


  useEffect(()=>{
    getBalanceUser(address,newInrx).then((res)=>{
      setNewBal(res);
  }).catch((err)=>{
     console.log(err);
  })

 },[address])

  return (
    <>
      <div>
      <Toaster />
        <Header />

        <div className="page-content bg-top-4">
          <div className="container">
            <div className="mb-5">
              <h4 className="heading text-center">
                Token <span className="span-gradient">Migration</span>
              </h4>
              <p className="text-center text-gray">
                Transform from{" "}
                <span className="text-purple">Old INRx </span> Token to
                <span className="text-purple"> New INRx</span> Token at 1:1
                ratio
              </p>
            </div>
            <div className="row formStyle">
              <div className="col-lg-6 m-auto">
                <div className="custom_card padding_p4">
                  <section>
                    <div className="d-flex justify-content-between mb-1">
                      <div className="text-gray">From</div>
                      <div className="token-info">
                        <div className="d-flex gap-2">
                          <div className="wallet-info">{bal}</div>
                          <div>
                            <span className=" me-2">Old INRx</span>
                          </div>
                        </div>
                        <div className="token-max ms-2 disabled" onClick={handleClick}>MAX </div>
                      </div>
                    </div>
                    <div className="input-group">
                      <input
                        placeholder="Enter an amount"
                        className="form-control"
                        type="number"
                        min="0"
                        value={val}
                        onChange={(e) => setVal(e.target.value)}
                      />
                      <span className="input-group-text">
                        <img
                          src="/img/logo/white@72x.png"
                          alt="INRX logo"
                          className="token-logos"
                        />
                        <span>Old INRx </span>
                      </span>
                    </div>
                    <div className="text-start mt-1">
                      <span className="text-gray me-1">Conversion Ratio</span>
                      1:1
                    </div>
                  </section>
                  <section className="my-4">
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="convert-arrow-bg">
                        <img src="/img/convert-circle.svg" alt="convert" />
                      </div>
                    </div>
                  </section>
                  <section>
                    <div className="d-flex justify-content-between mb-1">
                      <div className="text-gray">To</div>
                      <div className="token-info">
                        <div className="d-flex gap-2 align-items-center">
                          <div className="wallet-info">{newBal}</div>
                          <div>
                            <span className="ms-2">New INRx</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="input-group">
                      <input
                        placeholder="Enter an amount"
                        className="form-control"
                        type="text"
                        value={val}
                        disabled
                      />
                      <span className="input-group-text">
                        <img
                          src="/img/logo/white@72x.png"
                          alt="inrx logo"
                          className="token-logos"
                        />
                        <span>New INRx</span>
                      </span>
                    </div>
                    <div className="swap-error-tip">
                      <span>&nbsp;</span>
                    </div>
                  </section>

                  <div className="d-grid my-3 btn-hover color-7 w-100">
                    <ConnectButton.Custom>
                      {({
                        account,
                        openConnectModal,
                        mounted,
                        authenticationStatus,
                      }) => {
                        const ready = mounted && authenticationStatus !== "loading";
                        const connected =
                          ready &&
                          account &&
                          (!authenticationStatus || authenticationStatus === "authenticated");

                        return (
                          <div
                            {...(!ready && {
                              "aria-hidden": true,
                              style: {
                                opacity: 0,
                                pointerEvents: "none",
                                userSelect: "none",
                              },
                            })}
                          >
                            {(() => {
                              if (!connected) {
                                return (
                                  <button
                                    onClick={openConnectModal}
                                    type="button"
                                    className="swap-approve btn text-white"
                                  >
                                    Connect Wallet
                                  </button>
                                );
                              }

                              return (
                                <button
                                  onClick={migrateToken}
                                  type="button"
                                  className="swap-approve w-100 btn text-white mx-auto"
                                >
                                  Swap
                                </button>
                              );
                            })()}
                          </div>
                        );
                      }}
                    </ConnectButton.Custom>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />

      </div>
    </>
  );
}