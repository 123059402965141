import moment from 'moment/moment'
import React from 'react'

export default function Table(props) {
    const {
        key,
        from,
        timestamp,
        amount,
        usdtPrice
    } = props
    return (
        <>
            <div>
                <div className='row p-2 mt-2 rounded bg-table-color' key={key}>
                    <div className='col-3'>{(from)?.slice(0, 6)}...{(from)?.slice(-3)}</div>
                    <div className='col-3'>{moment(timestamp,).fromNow()}</div>
                    <div className='col-3'>{((amount / 1e18) / (usdtPrice))?.toFixed()}</div>
                    <div className='col-3'>{(amount) / 1e18} <img width={20} src="/img/logo/white@72x.png" alt="INRx"></img></div>
                </div>
            </div>
        </>
    )
}
