import React from "react";
import "../css/Market.css";
import Marqueee from "./Marquee";
import "../css/AboutUS.css";
import "../css/Careers.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Link } from "react-router-dom";
import Member from "./Member";

export default function Market() {
  const Slider_data = [
    {
      id: "1",
      Name: "Naman Singh",
      p_name: "CEO",
      alt_c_name: "INRx",
      // logo: "/img/logo/white@72x.png",
      user_img: "https://www.nadcab.com/public/uploads/naman_singh_nadcab.jpg",
      text: "INRx's mission to make solid assets available to everyone as a means of launching a business is inspiring, and I can't wait to be a part of it.",
    },
    {
      id: "2",
      Name: "Wazid Khan ",
      p_name: "Partner",
      alt_c_name: "Analog Labs ",
      // logo: "/img/logo/analog_icon.svg",
      user_img: "https://www.nadcab.com/public/uploads/RCP_1356.JPG",
      text: "We are excited to support INRxs efforts since we think they will have a profound effect on startups everywhere.",
    },
    // {
    //   id: "3",
    //   Name: "Danny Olinsky",
    //   p_name: "Co-founder",
    //   alt_c_name: "Statuspage",
    //   // logo: "https://assets.website-files.com/609486d2fafa37698a57db5b/622a1d6a14193828db36291f_firstbase-statuspage.svg",
    //   user_img:
    //     "https://assets.website-files.com/609486d2fafa37698a57db5b/60d4b291dd72bbd0060627f6_firstbase-dannyolinsky.jpg",
    //   text: "It's not easy to make the decision to release Defi for the Indian community. INRx transforms the Defi process, which can be a major source of stress for startup owners, into a streamlined, individualised, and highly effective one-stop shop.",
    // },

    // {
    //   id: "3",
    //   Name: "Raj A. Kapoor",
    //   p_name: "FOUNDER",
    //   alt_c_name: "India Blockchain Alliance",
    //   logo: "https://img1.wsimg.com/isteam/ip/b0d035f4-c635-4838-bf3a-57a4392f28ca/India%20Blockchain%20alliance%20(1).png/:/rs=w:99,h:99,cg:true,m/cr=w:99,h:99/qt=q:95",
    //   user_img: "https://inrx.tv/wp-content/uploads/2022/08/1660108469457-100x100.jpg",
    //   text: "The only way to make the rollout of a Decentralized Finance really easy is for a company like INRx to take on an issue as large as its and as complex as the many government bodies involved.",
    // },
  ];
  return (
    <>
      <div className="">
        <div className="row d-none">
          <div className="supportingInstitutions">
            <h3 className="mt-5 mb-3 text-light">
              INR
              <span className="fs-4 coin-name" style={{ color: "#03e804" }}>
                x
              </span>{" "}
              Markets
            </h3>
            <Marqueee />
          </div>
        </div>
        <div className="container">
          <div className="pt-5">
            <AliceCarousel
              autoPlay
              // autoPlayControls
              autoPlayStrategy="none"
              autoPlayInterval={2000}
              animationDuration={0}
              animationType="fadeout"
              infinite
              touchTracking={false}
              disableDotsControls
              disableButtonsControls
            >
              {Slider_data &&
                Slider_data?.map((e, i) => {
                  return (
                    <div key={i}>
                      <div className="div-block-335">
                        <span className="d-flex fw-bold border p-2  rounded">
                          {/* <img
                          width={60}
                          src={e.logo}
                          loading="lazy"
                          alt={e.alt_c_name}
                          className="image-94"
                        /> */}
                          {e.alt_c_name}
                        </span>
                        <p className="paragraph-23">{e.text}</p>
                        <img
                          src={e.user_img}
                          loading="lazy"
                          sizes="(max-width: 1279px) 40px, (max-width: 1439px) 3vw, 40px"
                          alt={`${e.name},${e.p_name}of${e.alt_c_name}`}
                          className="image-95"
                        />

                        <h3 className="heading-77">{e.name}</h3>
                        <div className="text-block-84">{e.p_name}</div>
                      </div>
                    </div>
                  );
                })}
            </AliceCarousel>
          </div>

          <div>
            <div className="spacer us wf-section">
              <div className="section-container center w-container">
                <div className="div-block-402">
                  <div className="text-block-106">Coming Soon 🔥</div>
                  <div className="text-block-106">INRx Raise™ Beta</div>
                </div>
                <h2 className="heading">
                  Fiat Backed INRx will launched Q1 2024
                </h2>
                <p className="section-paragraph mb-3">
                  INRx will be backed with Indian Fiat INR before the first
                  quarter of 2024.
                  <br />
                  INRx mechanism LLP continuously work with govt advisory board
                  to completing noms.
                  <br />
                </p>
                <div className="referral-partner-container">
                  <div className="gradient-btn">
                    <div className="gradient--btn">
                      <a
                        href=""
                        target="_blank"
                        className="gradient---btn w-button"
                      >
                        Register for Raise™ Beta launch
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="image_bg">
            <div className="spacer">
              <div className="">
                <div className="call-to-action-section text-center">
                  <div className="call-to-action-inner">
                    <h2 className="heading-86">Mint Your INRx</h2>
                    <p className="text-gray mb-3">
                      Start, and manage your trade with understandable currency
                      value in your own terms.
                    </p>
                    <div className="d-flex gap-3 justify-content-center mt-3 align-items-center">
                      <div className="gradient-btn-nn">
                        <div className="gradient--btn-nn">
                          <Link to="https://auth.inrx.io/" className="btn-hover color-4">
                            Mint INRx
                          </Link>
                        </div>
                      </div>
                    <div className="">  <a href="https://auth.inrx.io/" target="_blank" className="secondary-btn-nn w-button-nn">
                        Stake INRx
                      </a></div>
                    </div>
                  </div>
                </div>
              </div>         
            </div>
            <div className="spacer">
            <Member />
            </div>
          </div>
        
        </div>
      </div>
    </>
  );
}
