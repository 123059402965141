import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Header } from "./Header";
import "../css/PsmSwap.css";
import "../css/Tabs.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setTronConfig } from "../redux/reducer/user";
import { onBuyTron, onConnectTron } from "../helpers/getWeb3";
import { Pagination } from "@material-ui/lab";
import moment from "moment/moment";
import BlockchainTabs from "./Blockchain-Tabs";
import Table from "./Table";

export default function Tron() {

  const [send, setSend] = useState("usdt");
  const [recieve, setRecieve] = useState("inrx");
  const [val, setVal] = useState(0);
  const [sendValue, setSendValue] = useState(0);
  const [recieveValue, setRecieveValue] = useState(0);
  const [CurrentINRxPrice, SetCurrentINRxPrice] = useState(0);
  const [txn_data, setTxnData] = useState();
  const dispatch = useDispatch();
  const { tronConfig } = useSelector((state) => state.user.value);
  const [start, setStart] = useState(0);

  const swapCurrency = () => {
    setRecieve(() => send);
    setSend(() => recieve);
    setSendValue(() => recieveValue);
    setRecieveValue(() => sendValue);
  };
  const handleChange = (e, p) => {
    setStart((p * 10) - 10)
  }
  useEffect(() => {
    axios
      .get(`https://min-api.cryptocompare.com/data/price?fsym=USDT&tsyms=INR`)
      .then((res) => {
        const orderrespons = res.data.INR;
        SetCurrentINRxPrice(orderrespons);
      })
      .catch((error) => {
        // console.log(error.message);
      }, []);

    axios
      .get(`https://shastapi.tronscan.org/api/token_trc20/transfers?limit=${10}&start=${start}&contract_address=TSGihL15TNgjhoU2gbW1ynMgBEESwidZ8W&confirm`, {
      }).then((res) => {
        setTxnData(res.data);
      })
  }, [start]);

  const tronData = async () => {
    onConnectTron().then((d) => {
      // console.log(d, "12345")
      dispatch(setTronConfig({ tronConfig: d }))
    })
  }

  useEffect(() => {
    (async () => {
      tronData();

    })();
  }, [])
  return (
    <>
      <div className="tron-usdd-wrapper bg_psm">
        <Header />
        <div className="container">
          <div className="row">
            <section className="psm-swap-container">
              <BlockchainTabs />
              {/* <div className=" mb-4">
                <ul className="nav nav-tabs">
                  <li
                    className="nav-item"
                    onClick={async (e) => {
                      window.location.reload();
                    }}
                  >
                    <Link 
                        to="/cps-swap/analog"
                        className={
                        networks == "analog"
                          // tabactive?.poly === true
                          ? "nav-link active bg-transparent"
                          : "nav-link bg-transparent"
                      }
                    aria-current="page">
                      <img src="/img/tabs_img/Analog_icon new.svg" />
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    onClick={async (e) => {
                      window.location.reload();
                    }}
                  >
                    <Link    
                        to="/cps-swap/ethereum"
                        className={
                        networks == "ethereum"
                          // tabactive?.poly === true
                          ? "nav-link active bg-transparent"
                          : "nav-link bg-transparent"
                      } aria-current="page">
                      <img src="/img/tabs_img/eth.svg" />
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    onClick={async (e) => {
                      window.location.reload();
                    }}
                  >
                    <Link
                        to="/cps-swap/binance"
                        className={
                        networks == "binance"
                          // tabactive?.poly === true
                          ? "nav-link active bg-transparent"
                          : "nav-link bg-transparent"
                      }>
                      <img src="/img/tabs_img/bsc.svg" alt="" />
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    onClick={async (e) => {
                      onConnect(networks).then(res=>dispatch(setConfig({config:res}))).catch(e=>{
                        console.log("Error::",e);
                      })
                    }}
                  >
                    <Link
                    to="/cps-swap/polygon"
                    className={
                        networks == "polygon"
                          // tabactive?.poly === true
                          ? "nav-link active bg-transparent"
                          : "nav-link bg-transparent"
                      }>
                      <img src="/img/tabs_img/polygon.svg" alt="" />
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => {

                    }}
                  >
                    <a 
                      className={
                        location.pathname === "/cps-swap/tronSwap"
                          ? "nav-link active bg-transparent"
                          : "nav-link bg-transparent"
                      }
                    >
                      <img src="/img/tabs_img/tron.svg" alt="" />
                    </a>
                  </li>
                  {/* <li className="nav-item" onClick={() => {
                  }}>
                    <Link to="/solanaSwap" className="nav-link bg-transparent">
                      <img src="/img/tabs_img/solana.svg" alt="" />
                    </Link>
                  </li> *
                </ul>
              </div> */}

              <section className="psm-swap-cont">
                <section className="psm-description">
                  <div className="des-content-title">About CPS</div>
                  <div className="des-content">
                    <div>
                      <div className="des-item-title">What is CPS?</div>
                      <div className="des-item-content">
                        The INRx Foundation and the INRx Foundation have come up with
                        a joint depositing tool called Current Price Stability (CPS),
                        with the intention of maintaining the value of the INRx Stable
                        asset. Users have the ability to "Mint" INRx at the present price
                        from a pegged asset that is stable and does not experience price slippage.
                      </div>
                      <div className="des-item-title">CPS Working Mechanism?</div>
                      <div className="des-item-content">
                        The INRx Foundation, in collaboration with the INRx Foundation, has developed
                        a CPS algorithim for creating a stable crypto currency (INRx) that will be
                        pegged to the Indian rupee (INR). Stable USD may be deposited on a INRx Reservoir
                        contract, and INRx can be minted based on the exchange rate between the two currencies.
                      </div>
                      <div className="des-item-title">Why INRx? </div>
                      <div className="des-item-content">
                        India's crypto community will account for 33% of the worldwide total, yet the country
                        lacks its own solid asset with which to trade in the secondary market. The INRx foundation
                        will provide infrastructure for digital assets that are similar to the Indian Rupee within
                        the context of the Indian community.
                      </div>
                      <div className="des-item-title">
                        <Link
                          className="button-wrapper button-style1 address-enter-btn"
                          to=""
                          rel="noreferrer"
                        >
                          Whitepaper<i className="icon_goto"></i>
                        </Link>
                        <span className="button-wrapper button-style1 address-enter-btn">
                          INRx Reservoir Contract
                        </span>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="psm-swap">
                  <div className="psm-swap-title">
                    <div className="psm-swap-title-text">PSM</div>
                    <div className="psm-swap-amount-detail">Swap Limit</div>
                  </div>
                  <div className="psm-swap-des">
                    Transform between INRx and USDT at USDT current INR price.
                  </div>
                  <section className="psm-swap-box">
                    <div className="swap-input-box swap-from">
                      <div className="swap-input-title">
                        <div className="swap-input-title-text">From</div>
                        <div className="title-info">
                          <div className="swap-balance-text">
                            <span>
                              Wallet Balance
                              {send === "usdt" ? (
                                <span className="swap-balance-value me-1">
                                  {(tronConfig.tron_usdt_balance?.toString() / 1e18)} USDT
                                </span>
                              ) : (
                                <span className="swap-balance-value me-1">
                                  {(tronConfig.tron_inrx_balance?.toString() / 1e18)} INRx
                                </span>
                              )}
                            </span>
                          </div>
                          <div className="swap-input-title-max disabled">
                            MAX
                          </div>
                        </div>
                      </div>
                      <div className="swap-input">
                        <input
                          placeholder="Enter an amount"
                          className="ant-input noscroll"
                          type="number"
                          minLength="1"
                          maxLength="79"
                          spellCheck="false"
                          inputMode="decimal"
                          title="Token Amount"
                          autoComplete="off"
                          pattern="^[0-9]*[.,]?[0-9]*$"
                          // id="range_buy"
                          value={val}
                          onChange={(e) => setVal(() => e.target.value)}
                        />

                        <div className="ant-select ant-select-single ant-select-show-arrow">
                          <div className="ant-select-selector">
                            <div className="dropdown-area-box input-side">
                              {send === "usdt" ? (
                                <span className="ant-select-selection-item">
                                  <img
                                    src="/img/usdt.e3719fbd.svg"
                                    alt="ef"
                                    className="token-logo"
                                  />

                                  <span>USDT</span>
                                </span>
                              ) : (
                                <span className="ant-select-selection-item">
                                  <img
                                    src="/img/logo/white@72x.png"
                                    alt="TPYmHEhy5n8TCEfYGqW2rPxsghSfzghPDn icon"
                                    className="token-logo"
                                  />
                                  <span>INRx </span>
                                </span>
                              )}
                            </div>
                          </div>
                          <span
                            className="ant-select-arrow"
                            unselectable="on"
                            aria-hidden="true"
                            style={{ userSelect: "none" }}
                          >
                            <img src="/img/icon_down.ca360ec3.svg" alt="" />
                          </span>
                        </div>
                      </div>
                      <div className="text-start INR-balance-text mt-2 ms-1">
                        <span className="swap-balance-value me-1">
                          Current INRx Price:
                        </span>
                        <span className="INR-balance-value me-1">
                          {CurrentINRxPrice?.toFixed(2)} INRx
                        </span>
                      </div>
                      <div className="swap-error-tip">
                        <span>&nbsp;</span>
                      </div>
                    </div>
                    <div className="swap-arrow" onClick={swapCurrency}></div>
                    <div className="swap-input-box swap-to">
                      <div className="swap-input-title">
                        <div className="swap-input-title-text">To</div>
                        <div className="title-info">
                          <div className="swap-balance-text">
                            <span>
                              Wallet Balance
                              {recieve === "inrx" ? (
                                <span className="swap-balance-value">
                                  {(tronConfig.tron_inrx_balance?.toString() / 1e18)} INRx
                                </span>
                              ) : (
                                <span className="swap-balance-value">
                                  {(tronConfig.tron_usdt_balance?.toString() / 1e18)} USDT
                                </span>
                              )}
                            </span>
                          </div>
                          <div>
                            <img
                              className="swap_amount_tip-img"
                              src="/img/tipIcon.e41aebb7.svg"
                              alt="tip"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="swap-input">
                        <input
                          placeholder="Enter an amount"
                          className="ant-input"
                          type="text"
                          minLength="1"
                          maxLength="79"
                          spellCheck="false"
                          inputMode="decimal"
                          title="Token Amount"
                          autoComplete="off"
                          disabled
                          pattern="^[0-9]*[.,]?[0-9]*$"
                          value={
                            send === "usdt" ? (val * (tronConfig.tron_usdt_price?.toString() / 100)) : (val / (tronConfig.tron_usdt_price?.toString() / 100))
                          }
                          onChange={(e) => setVal(() => e.target.value)}
                        />
                        <div className="ant-select ant-select-single ant-select-show-arrow">
                          <div className="ant-select-selector">
                            <div className="dropdown-area-box input-side">
                              {recieve === "inrx" ? (
                                <span className="ant-select-selection-item">
                                  <img
                                    src="/img/logo/white@72x.png"
                                    alt="TPYmHEhy5n8TCEfYGqW2rPxsghSfzghPDn icon"
                                    className="token-logo"
                                  />
                                  <span>INRx</span>
                                </span>
                              ) : (
                                <span className="ant-select-selection-item">
                                  <img
                                    src="/img/usdt.e3719fbd.svg"
                                    alt="ef"
                                    className="token-logo"
                                  />
                                  <span>USDT</span>
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="swap-error-tip">
                        <span>&nbsp;</span>
                      </div>
                    </div>
                  </section>
                  <div className="swap-approve" onClick={() => {
                    onBuyTron(tronConfig.tronWeb, tronConfig.user_tron, val, tronData)
                  }}>BUY</div>
                </section>
              </section>
              <section className=" psm-descriptions">
                <div className="mobile-scroll-table ">
                  <div className="mobile-width">
                    <caption>Transactions</caption>
                    <div className='row p-2 rounded-3 bg-head-color'>
                      <div className='col-3'>From</div>
                      <div className='col-3'>Age</div>
                      <div className='col-3'>Collateral</div>
                      <div className='col-3'>Mint</div>
                    </div>
                    {
                      txn_data?.token_transfers?.map((element, i) => {
                        return (
                          <>
                            <Table
                              key={i.toString()}
                              from={element.from_address}
                              timestamp={element.block_ts}
                              amount={element.quant}
                              usdtPrice={element.trigger_info.parameter._usdAmount}
                            />
                          </>
                        )
                      })
                    }
                  </div>
                </div>

                {/* <div class="table-responsive">
                    <table class="table caption-top table-borderless">
                      <caption>Transactions</caption>
                      <thead class="table-dark">
                        <tr>
                          {/* <th scope="col">Hash</th> *
                          <th scope="col">From</th>
                          <th scope="col">Age</th>
                          <th scope="col">Collateral</th>
                          <th scope="col">Mint</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          txn_data?.token_transfers?.map((element, i) => {
                            return (
                              <>
                                <tr>
                                  <th scope="row">{(element.from_address)?.slice(0, 6)}...{(element.from_address)?.slice(-3)}</th>
                                  {/* <td>{(element.from)?.slice(0, 6)}...{(element.from)?.slice(-3)}</td> *
                                  <td>{moment(element.block_ts,).fromNow()}</td>
                                  <td>{((element.trigger_info.parameter._usdAmount/1e18))?.toFixed(2)}</td>
                                  <td>{((element.quant) / 1e18)?.toFixed(2)} <img width={20} src="/img/logo/white@72x.png" alt="INRx"></img></td>
                                </tr>
                              </>
                            )
                          })
                        }

                      </tbody>
                    </table>
                  </div> */}
                <div className=" d-flex justify-content-center mb-3 mt-1">
                  <Pagination color="primary" count={Math.ceil((txn_data?.rangeTotal) / 10)} onChange={handleChange} />
                </div>

              </section>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
