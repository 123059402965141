import React from 'react'
import '../css/Member.css'

// import Footer from './Footer';
export default function Member() {
  return (
    <>
      <div className='container member-container py-5 mt-5' >
        <div className='row justify-content-center'>
          <h3 className='heading fw-normal text-center pb-3'>Press</h3>
          <div className='row text-center pe-0'>
            <div className='col-lg-3 col-md-6 col-sm-6 col-6 p-0'>
              <div className='img-card-member'>
                <img src="img/abp.png" className='member-img' alt="Highlight of INRx Coin launch from ABP Live discussing its market potential." />
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6 col-6 p-0'>
              <div className='img-card-member'>
                <img src="img/midday.png" className='member-img' alt="The Print coverage of INRx Blockchain Network and its significance in the crypto industry." />
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6 col-6 p-0'>
              <div className='img-card-member'>
                <img src="img/upturn.png" className='member-img' alt="Mid Day article on INRx Stable Coin, detailing its features and advantages for users." />
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6  col-6 p-0'>
              <div className='img-card-member'>
                <img src="img/theprint.png" className='member-img' alt="Discussion on INRx Indian Stable Coin role in enhancing the Indian crypto landscape in Business Upturn." />
              </div>
            </div>
            
          </div>
         
        </div>
      </div>
    
    </>
  )
}
