import "./App.css";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Home } from "./Home";
import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "react-hot-toast";
import ReactTooltip from "react-tooltip";
import { Provider } from "react-redux";
import  {store}  from "../src/redux/store/user.js";
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
  midnightTheme
} from '@rainbow-me/rainbowkit';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import {bscTestnet, bsc ,polygon,polygonMumbai,sepolia } from 'wagmi/chains';

import { publicProvider } from 'wagmi/providers/public';


function App() {
  window.Buffer = window.Buffer || require("buffer").Buffer;
  const { chains, provider,webSocketProvider } = configureChains(
    [bsc,bscTestnet,polygon,polygonMumbai,sepolia],
    [publicProvider()],
    [
      jsonRpcProvider({
        rpc: (chain) => ({
          http: `${chain.rpcUrls.default.http[0]}`,
        }),
      }),
    ],

  );
  const { connectors } = getDefaultWallets({
    appName: 'My RainbowKit App',
    chains
  });
  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider
  })
  return (
    <>
     <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} modalSize="compact" theme={midnightTheme({
      accentColor: 'rgb(3, 232, 4)',
      accentColorForeground: 'white',
      borderRadius: 'small',
      fontStack: 'system',
      overlayBlur: 'small',
    })}>
      <BrowserRouter>
        <Provider store={store}>

          <Home />
        </Provider>
      </BrowserRouter>
      <Toaster
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
       </RainbowKitProvider>
    </WagmiConfig>
    </>
  );
}

export default App;
