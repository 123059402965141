import React from "react";
import "../css/Banner.css";
import Stablecoin from "./Stablecoin";
import Ecosystem from "./Ecosystem";
import Market from "./Market";
import Member from "./Member";
import { Header } from "./Header";
import { Link } from "react-router-dom";
import Typewriter from 'typewriter-effect';
import Footer from "./Footer";


export default function Banner() {

  return (
    <>
      <div>
        
        <div className="bg-img position-relative">        
          {/* <img className="bg-top-img" src="/img/bg/top-ban.svg" alt=""></img> */}
          <Header />
          <div className="position-relative">
            <div className="container spacer">
              <div className="home-main">
                <div className="pt-2 align-content-center" >
                  <div className=" d-flex banner-text justify-content-center">
                    <div className="ctext-center">
                      <strong className=" d-flex justify-content-center">
                        <div className="d-flex">
                          <span className="me-2"> <Typewriter
                            options={{
                              strings: ['Decentralized', 'Stable'],
                              autoStart: true,
                              loop: true,
                            }}
                          /> </span>
                          INR
                          <span style={{ color: "#03e804" }}>
                        x
                      </span> 
                        </div>
                      </strong>
                      <div className="d-flex justify-content-center text-ban">
                        <div className="ban-size">
                          <p className="text-center mt-0 ">
                            <Link to="/" className="text-linear" target="_blank" rel="noreferrer">
                              The INRx Governing Stable Currency
                            </Link>
                            &nbsp;preserves the value of INR
                            <span style={{ color: "#03e804" }}>
                              x
                            </span>{" "}
                            and guarantees that it will be circulated in the future.
                          </p>
                        </div>

                      </div>

                      <div className="btn-list d-flex justify-content-center">
                        <div className="head-btn-ban--- me-2">
                          <a
                            className="btn-hover color-4"
                            aria-current="page"
                            href="/assets/Onepager.pdf"
                            target="_blank"
                          >
                           One Pager
                          </a>
                        </div>
                        <div className="dropdown-area-box audit-report">
                          <li className="nav-item dropdown">
                            <a
                              className="btn-hover color-9"
                              href="/assets/new INRX.pdf"                             
                              target="_blank"
                              aria-current="page"
                            >
                              WhitePaper
                            </a>
                            <ul
                              className="dropdown-menu"  
                              aria-labelledby="navbarDropdownMenuLink"
                            >
                              {/* <li>
                                <a className="dropdown-item1" href="/assets/CertiK-Audit-for-INRx---Audit-v9.pdf" target="_blank">
                                <div className="d-flex align-items-center">
                                  <div className="circle_bg">
                                    <img src="img/logo/analog_icon.svg"
                                    alt=""
                                    className="ana_img"
                                    
                                  />
                                  </div>
                                  <div>
                                    Log20 INRx
                                  </div>

                                </div>
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item1" href="/assets/CertiK-Audit-for-INRx---Audit-v9.pdf" target="_blank">
                                <div className="d-flex align-items-center">
                                  <div className="circle_bg">
                                    <img
                                    src="img/erc1png.png"
                                    alt="" 
                                    className="erc_img"                                   
                                  />
                                  </div>
                                  <div>
                                  ERC20 INRx
                                  </div>
                                  </div>
                                </a>
                              </li> */}
                              <li>
                                <a className="dropdown-item1" href="/assets/CertiK-Audit-for-INRx---Audit-v9.pdf" target="_blank">
                                  <div className="d-flex align-items-center">
                                    <div className="circle_bg">
                                      <img
                                      src="img/bsc.png"
                                      alt=""
                                      className="bep_img"                                      
                                    />
                                    </div>
                                    <div>
                                     BEP20 INRx
                                    </div>
                                    </div>
                                 
                                </a>
                              </li>
                              {/* <li>
                                <a className="dropdown-item1" href="/assets/CertiK-Audit-for-INRx---Audit-v9.pdf" target="_blank">
                                <div className="d-flex align-items-center">
                                    <div className="circle_bg">
                                      
                                        <img
                                      src="img/tron-trx-logo.svg"
                                      alt=""
                                      //style={{ height: "15px" }}
                                      className="tron_img"
                                  />
                                  </div>
                                  TRC20 INRx</div>
                                </a>
                              </li> */}
                            </ul>
                          </li>
                        </div>

                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  {/* <div className="col-md-4  banner-image text-center">
                  <img src="img/element_inrx-01 (1).png" alt="" className="banner-img"/>
                </div> */}
                </div>
              </div>
              {/* <div className="text-center head-btn-ban--- mt-2">
                          <Link
                            className="btn-hover color-7 py-2"
                            aria-current="page"
                            to="/buywithupi"
                          >
                            Buy INRx With UPI
                          </Link>
                        </div> */}
            </div>

     
            <div className="bg-top-3--">
              <div className="bg-top-4--">
                <Stablecoin />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-top-2--">
          {/* <Transparency /> */}
          <Ecosystem />

          <Market />
         
        </div>
        <Footer />
      </div>
    </>
  );
}
