import  {BSC_CONTRACT_ADDRESS,BSC_CONTRACT_ABI,POLYGON_CONTRACT_ADDRESS,POLYGON_CONTRACT_ABI, ETH_CONTRACT_ADDRESS, ETH_CONTRACT_ABI} from "./config.js";
import Web3 from "web3";
const BSC_RPC_URL="https://rpc.ankr.com/bsc"
const POLYGON_RPC_URL="https://polygon-rpc.com"
const ETH_RPC_URL="https://eth.llamarpc.com	"

const getBscTotalSupply = async() =>{
    const web3 = new Web3(BSC_RPC_URL);
    const contract = new web3.eth.Contract(BSC_CONTRACT_ABI, BSC_CONTRACT_ADDRESS);
    try {
        let TotalSupply = await contract.methods.totalSupply().call()
        const OwnerAddress = await contract.methods.owner().call()
        let OwnerBalance = await contract.methods.balanceOf(OwnerAddress).call()
        TotalSupply = TotalSupply/1e18
        OwnerBalance = OwnerBalance/1e18
        return {TotalSupply,OwnerBalance}
    } catch (error) {
        console.log(error)
    }
}
const getPolygonTotalSupply = async() =>{
    const web3 = new Web3(POLYGON_RPC_URL);
    const contract = new web3.eth.Contract(POLYGON_CONTRACT_ABI, POLYGON_CONTRACT_ADDRESS);
    try {
        let TotalSupply = await contract.methods.totalSupply().call()
        const OwnerAddress = await contract.methods.owner().call()
        let OwnerBalance = await contract.methods.balanceOf(OwnerAddress).call()
        TotalSupply = TotalSupply/1e18
        OwnerBalance = OwnerBalance/1e18
        return {TotalSupply,OwnerBalance}
    } catch (error) {
        console.log(error)
    }
}

const getETHTotalSupply = async() =>{
    const web3 = new Web3(ETH_RPC_URL);
    const contract = new web3.eth.Contract(ETH_CONTRACT_ABI, ETH_CONTRACT_ADDRESS);
    try {
        let TotalSupply = await contract.methods.totalSupply().call()
        const OwnerAddress = await contract.methods.owner().call()
        let OwnerBalance = await contract.methods.balanceOf(OwnerAddress).call()
        TotalSupply = TotalSupply/1e18
        OwnerBalance = OwnerBalance/1e18
        return {TotalSupply,OwnerBalance}
    } catch (error) {
        console.log(error)
    }
}

export {getBscTotalSupply,getPolygonTotalSupply,getETHTotalSupply}