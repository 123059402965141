import React from "react";
import { useState, useEffect } from "react";
import "../css/Ecosystem.css";
import { AiFillFire } from "react-icons/ai";
import Card_Ecosystem from "./Card_Ecosystem";


export default function Ecosystem() {
  const [card, setCard] = useState([]);
  const [active, setActive] = useState("All");


  // function openTab(evt, cardName) {
  //   var i, tabcontent, tablinks;
  //   tabcontent = document.getElementsByClassName("tabcontent");
  //   for (i = 0; i < tabcontent.length; i++) {
  //     tabcontent[i].style.display = "none";
  //   }
  //   tablinks = document.getElementsByClassName("tablinks");
  //   for (i = 0; i < tablinks.length; i++) {
  //     tablinks[i].className = tablinks[i].className.replace(" active", "");
  //   }
  //   document.getElementById(cardName).style.display = "block";
  //   evt.currentTarget.className += "active";
  // }

  const cCard = [
    {
      id: "1",
      Name: "Analog",
      Logo: "/img/logo/analog_icon.svg",
      Pair_logo: "/img/logo/INRX USDA.svg",
      Pair_Name: "USDA",
      Stake_amount: "$927080",
      Apy: "204.1%",
    },
    {
      id: "2",
      Name: "Ethereum",
      Logo: "/img/erc1png.png",
      Pair_logo: "/img/INRX USDT.svg",
      Pair_Name: "USDT",
      Stake_amount: "$927080",
      Apy: "204.1%",
    },
    {
      id: "3",
      Logo: "img/bsc.png",
      Name: "BNB",
      Pair_logo: "/img/INRX busd.svg",
      Pair_Name: "BUSD",
      Stake_amount: "$927080",
      Apy: "204.1%",
    },
    {
      id: "4",
      Name: "Polygon",
      Logo: "/img/tabs_img/polygon.svg",
      Pair_logo: "/img/logo/INRX USDC.svg",
      Pair_Name: "USDC",
      Stake_amount: "$927080",
      Apy: "204.1%",
    },
    {
      id: "5",
      Name: "Analog",
      Logo: "img/logo/analog_icon.svg",
      Pair_logo: "/img/logo/INRX ANA.svg",
      Pair_Name: "ANA",
      Stake_amount: "$927080",
      Apy: "204.1%",
    },
    {
      id: "5",
      Name: "Analog",
      Logo: "img/logo/analog_icon.svg",
      Pair_logo: "/img/logo/INRX CALCULAS.svg",
      Pair_Name: "CALSE",
      Stake_amount: "$927080",
      Apy: "204.1%",
    },
    
    // {
    //   id: "5",
    //   Name: "Tron",
    //   Logo: "/img/tron-trx-logo.svg ",
    //   Pair_logo: "img/logo/INRX USDC.svg",
    //   Pair_Name: "USDC",
    //   Stake_amount: "$927080",
    //   Apy: "204.1%",
    // },

  ]
  useEffect(() => {
    setCard(cCard);
  }, [])

  function tabClick(block) {
    console.log(block, "block")
    const cards = cCard.filter((element) => element.Name === block)
    setCard(cards)
  }

  return (
    <>
      <div className="d-none">
        <div className="container">
          <div className="row">
            <div className="ecology">
              <h3 className="text-light">
                INR
                <span className="fs-4 coin-name" style={{ color: "#03e804" }}>
                  x
                </span>{" "}
                Ecosystem
              </h3>
              <div className="ecology-component">
                <div className="ecology-header">
                  <div className="title">
                    <span className="text-warning fs-2">
                      <AiFillFire />
                    </span>
                    <span className="text-light">
                      {" "}
                      INR
                      <span className="fs-6" style={{ color: "#03e804" }}>
                        x
                      </span>{" "}
                      Staking
                    </span>
                    <div className="apy">APY 30%+</div>
                  </div>
                  <div className="filter-wrapper">
                    <div className="tab-btn-list ">
                      <button className={active === "All" ? "active mb-3" : "mb-3"}
                        onClick={() => { setCard(cCard); setActive("All") }}
                        id="defaultOpen"
                      >All</button>
                      <button
                        className={active === "Analog" ? "active mt-3 tablinks" : "mt-3 tablinks"}
                        onClick={() => { tabClick("Analog"); setActive("Analog") }}
                      >
                        <img
                          src="/img/logo/analog_icon.svg"
                          alt=""
                          className="pe-1 pb-1"
                          style={{ height: "15px" }}
                        />
                        Analog
                      </button>
                      <button
                        className={active === "Ethereum" ? "active mt-3 tablinks" : "mt-3 tablinks"}
                        onClick={() => { tabClick("Ethereum"); setActive("Ethereum") }}
                      >
                        <img
                          src="/img/erc1png.png"
                          alt=""
                          style={{ height: "15px" }}
                          className="pe-1"
                        />
                        Ethereum
                      </button>
                      <button
                        className={active === "BNB" ? "active mt-3 tablinks" : "mt-3 tablinks"}
                        onClick={() => { tabClick("BNB"); setActive("BNB") }}
                      >
                        <img
                          src="/img/bsc.png"
                          alt=""
                          style={{ height: "15px" }}
                          className="pe-1"
                        />
                        BNB Chain
                      </button>
                      <button className={active === "Polygon" ? "active mt-3 tablinks" : "mt-3 tablinks"}
                        onClick={() => { tabClick("Polygon"); setActive("Polygon") }}
                      >
                        <img
                          src="/img/tabs_img/polygon.svg"
                          alt=""
                          style={{ height: "13px" }}
                          className="pe-1"

                        />
                        POLYGON
                      </button>
                      {/* <button className={active === "Tron" ? "active mt-3 tablinks" : "mt-3 tablinks"}
                        onClick={() => { tabClick("Tron"); setActive("Tron") }}
                      >
                        <img
                          src="/img/tron-trx-logo.svg"
                          alt=""
                          style={{ height: "13px" }}
                          className="pe-1"

                        />
                        TRON
                      </button> */}
                    </div>
                  </div>
                </div>

                <div
                  className="ecology-contain"
                  style={{
                    // backgroundImage: "url('/img/staking_rb.64fddee5.svg')",
                    // backgroundImage: "url('/img/staking_lt.e3fe387a.png')",
                    // backgroundSize: "cover",
                    maxHeight: "550px",
                    overflowY: "auto",
                    marginLeft: "0px",
                    paddingBottom: "20px"
                  }}
                >
                  <div className="row">
                    {card.map((data) => {
                      return (
                        <>
                          <Card_Ecosystem
                            id={data.id}
                            Logo={data.Logo}
                            Name={data.Name}
                            Pair_logo={data.Pair_logo}
                            Pair_Name={data.Pair_Name}
                            Stake_amount={data.Stake_amount}
                            Apy={data.Apy}
                          />
                        </>
                      );
                    })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
