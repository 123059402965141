import React, { useState } from "react";
import { Header } from "../Header";
import "../../css/Careers.css";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import Footer from "../Footer";
// import { Slide } from 'react-toastify';

export default function Careers() {
  const [slidee, setSlide] = useState("div1");

  function slide() {
    const currentDiv =
      slidee === "div1"
        ? "div2"
        : slidee === "div2"
        ? "div3"
        : slidee === "div3"
        ? "div4"
        : slidee === "div4"
        ? "div5"
        : slidee === "div5"
        ? "div6"
        : slidee === "div6"
        ? "div7"
        : "div1";
    setSlide(currentDiv);
    // console.log(currentDiv, "CURRENT____DIVVV");
  }
  function slideP() {
    const currentDiv =
      slidee === "div2"
        ? "div1"
        : slidee === "div3"
        ? "div2"
        : slidee === "div4"
        ? "div3"
        : slidee === "div5"
        ? "div4"
        : slidee === "div6"
        ? "div5"
        : slidee === "div7"
        ? "div6"
        : "div1";
    setSlide(currentDiv);
    // console.log(currentDiv, "CURRENT____DIVVV");
  }

  return (
    <>
      <Header />
      <div className="container-fluid--">
        <div className="container page-content">
          <div>
            <div className="section-container--">
              <h1 className="heading text-center">
                <span className="span-gradient">Join </span>our team
              </h1>
              <p className="text-gray text-center">
                Join us as we support and help more Web3 projects thrive
              </p>
            </div>
          </div>
          <div className="spacer">
            <div className="section-container-- w-container--">
              <h2 className="h3">Benefits of working with us.</h2>
              <p className="text-gray mb-4">
                INRx values its employees' individual styles of work and has
                designed a workplace to support their development.
              </p>

              <ul className="list">
                <li>
                  Learning Web3-
                  <span className="text-gray ms-1">
                    Gain hands-on experience with decentralized applications and
                    smart contracts in Web3
                  </span>
                  <br />
                </li>
                <li>
                  Embracing Decentralization-
                  <span className="text-gray ms-1">
                    Understand the benefits of a decentralized financial system
                    and reduced intermediaries.
                  </span>
                </li>
                <li>
                  Blockchain Technology-
                  <span className="text-gray ms-1">
                    Explore blockchain’s immutability, cryptographic security,
                    and distributed ledger systems for secure transactions.
                  </span>
                </li>
                <li>
                  Liquidity Provision
                  <span className="text-gray ms-1">
                    Participate in liquidity pools, understanding market making
                    and decentralized exchange dynamics.
                  </span>
                </li>
                <li>
                  DeFi Engagement -
                  <span className="text-gray ms-1">
                    Experience borrowing, lending, staking, and yield farming
                    without traditional financial intermediaries.
                  </span>
                </li>
                <li>
                  Technical Skill Development
                  <span className="text-gray ms-1">
                    Enhance blockchain and DeFi development skills, including
                    smart contract interaction and dApp creation.
                  </span>
                </li>
                <li>
                  Transparency and Security
                  <span className="text-gray me-1">
                    Benefit from blockchain’s transparency and cryptographic
                    security for trustworthy financial transactions
                  </span>
                </li>
                <li>
                  Inclusive Finance
                  <span className="text-gray me-1">
                    Evaluate DeFi’s potential for providing more inclusive,
                    efficient financial services for all users.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="spacer">
            <div className="section-container--">
              <h2 className="heading">Employee Testimonials on INRx</h2>
              <p className="text-gray">
                INRx empowers us to explore Web3 and DeFi, enhancing our skills
                in decentralized applications, liquidity provision, and secure,
                transparent financial transactions. It's a gateway to the future
                of finance.
              </p>
              <div className="slide_contain">
                <div className="slide_wrap w-dyn-list">
                  <div role="list" className="slide_list w-dyn-items">
                    <div
                      role="listitem"
                      className=" div1 slide_item w-dyn-item is--current"
                      style={{
                        transform:
                          slidee === "div1"
                            ? "translateX(0px)"
                            : "translateX(0px)",
                      }}
                    >
                      <div className="slide_card">
                        <div className="div-block-243">
                          <p className="quote">
                            Working with INRx has been a transformative
                            experience. The platform's emphasis on Web3 and DeFi
                            technologies has significantly enhanced my
                            understanding of decentralized applications and
                            blockchain security. The hands-on involvement in
                            liquidity provision has been particularly
                            enlightening, providing insights into market
                            dynamics and decentralized exchanges. INRx is truly
                            a gateway to the future of finance, offering
                            inclusive and efficient financial solutions
                          </p>
                          <div className="div-block-244">
                            <p className="quote">John Smith</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      role="listitem"
                      className="div2 slide_item w-dyn-item"
                      style={{
                        transform:
                          slidee === "div1"
                            ? "translateX(0px)"
                            : slidee === "div2"
                            ? window.innerWidth >= 500
                              ? "translateX(-400px)"
                              : "translateX(-355px)"
                            : window.innerWidth >= 500
                            ? "translateX(-400px)"
                            : "translateX(-355px)",
                      }}
                    >
                      <div className="slide_card">
                        <div className="div-block-243">
                          <p className="quote">
                            INRx has revolutionized my perspective on
                            decentralized finance. The ability to engage with
                            smart contracts and liquidity pools has been
                            incredibly educational. I appreciate the
                            transparency and security that blockchain technology
                            brings to financial transactions. INRx's innovative
                            use of Web3 has opened up new avenues for
                            professional growth and skill development. It's an
                            exciting time to be part of this transformative
                            industry
                          </p>
                          <div className="div-block-244">
                            <p className="quote">Emily Brown</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      role="listitem"
                      className="div3 slide_item w-dyn-item"
                      style={{
                        transform:
                          slidee === "div1"
                            ? "translateX(0px)"
                            : slidee === "div2"
                            ? window.innerWidth >= 500
                              ? "translateX(-400px)"
                              : "translateX(-355px)"
                            : slidee === "div3"
                            ? window.innerWidth >= 500
                              ? "translateX(-800px)"
                              : "translateX(-710px)"
                            : window.innerWidth >= 500
                            ? "translateX(-800px)"
                            : "translateX(-710px)",
                      }}
                    >
                      <div className="slide_card">
                        <div className="div-block-243">
                          <p className="quote">
                            Being a part of INRx has been a remarkable journey.
                            The platform's focus on Web3 and DeFi has provided
                            me with a deep understanding of decentralized
                            finance mechanisms. Participating in liquidity
                            provision and yield farming has given me practical
                            experience in managing decentralized financial
                            systems. INRx's commitment to transparency and
                            security aligns perfectly with my values, making it
                            a rewarding and educational experience
                          </p>
                          <div className="div-block-244">
                            <p className="quote">Rahul Mehta</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      role="listitem"
                      className="div4 slide_item w-dyn-item"
                      style={{
                        transform:
                          slidee === "div1"
                            ? "translateX(0px)"
                            : slidee === "div2"
                            ? window.innerWidth >= 500
                              ? "translateX(-400px)"
                              : "translateX(-355px)"
                            : slidee === "div3"
                            ? window.innerWidth >= 500
                              ? "translateX(-800px)"
                              : "translateX(-710px)"
                            : slidee === "div4"
                            ? window.innerWidth >= 500
                              ? "translateX(-1200px)"
                              : "translateX(-1064px)"
                            : window.innerWidth >= 500
                            ? "translateX(-1200px)"
                            : "translateX(-1064px)",
                      }}
                    >
                      <div className="slide_card">
                        <div className="div-block-243">
                          <p className="quote">
                            INRx has provided me with invaluable insights into
                            the world of decentralized finance. The platform's
                            innovative approach to Web3 has allowed me to
                            develop skills in smart contract interaction and
                            decentralized application development. I have gained
                            a comprehensive understanding of liquidity provision
                            and market dynamics through hands-on experience.
                            INRx is paving the way for a more inclusive and
                            efficient financial future
                          </p>
                          <div className="div-block-244">
                            <p className="quote">Sneha Patel</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      role="listitem"
                      className="div5 slide_item w-dyn-item"
                      style={{
                        transform:
                          slidee === "div1"
                            ? "translateX(0px)"
                            : slidee === "div2"
                            ? window.innerWidth >= 500
                              ? "translateX(-400px)"
                              : "translateX(-355px)"
                            : slidee === "div3"
                            ? window.innerWidth >= 500
                              ? "translateX(-800px)"
                              : "translateX(-710px)"
                            : slidee === "div4"
                            ? window.innerWidth >= 500
                              ? "translateX(-1200px)"
                              : "translateX(-1064px)"
                            : slidee === "div5"
                            ? window.innerWidth >= 500
                              ? "translateX(-1600px)"
                              : "translateX(-1418px)"
                            : window.innerWidth >= 500
                            ? "translateX(-1600px)"
                            : "translateX(-1418px)",
                      }}
                    >
                      <div className="slide_card">
                        <div className="div-block-243">
                          <p className="quote">
                            Working with INRx has been an eye-opener. The
                            exposure to Web3 and DeFi technologies has broadened
                            my knowledge of decentralized financial systems. The
                            platform's emphasis on blockchain security and
                            transparency has reinforced the importance of
                            trustworthy financial transactions. INRx's practical
                            approach to liquidity provision and decentralized
                            exchanges has been instrumental in my professional
                            growth
                          </p>
                          <div className="div-block-244">
                            <p className="quote">Aman Vaths</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      role="listitem"
                      className="div6 slide_item w-dyn-item"
                      style={{
                        transform:
                          slidee === "div1"
                            ? "translateX(0px)"
                            : slidee === "div2"
                            ? window.innerWidth >= 500
                              ? "translateX(-400px)"
                              : "translateX(-355px)"
                            : slidee === "div3"
                            ? window.innerWidth >= 500
                              ? "translateX(-800px)"
                              : "translateX(-710px)"
                            : slidee === "div4"
                            ? window.innerWidth >= 500
                              ? "translateX(-1200px)"
                              : "translateX(-1064px)"
                            : slidee === "div5"
                            ? window.innerWidth >= 500
                              ? "translateX(-1600px)"
                              : "translateX(-1418px)"
                            : slidee === "div6"
                            ? window.innerWidth >= 500
                              ? "translateX(-2000px)"
                              : "translateX(-1771px)"
                            : window.innerWidth >= 500
                            ? "translateX(-2000px)"
                            : "translateX(-1771px)",
                      }}
                    >
                      <div className="slide_card">
                        <div className="div-block-243">
                          <p className="quote">
                            INRx has been a game-changer in my career. The
                            platform's focus on Web3 and DeFi has allowed me to
                            explore the potential of decentralized finance.
                            Engaging with smart contracts and liquidity pools
                            has provided me with hands-on experience and a
                            deeper understanding of blockchain technology.
                            INRx's commitment to security and transparency
                            ensures a reliable and educational experience
                          </p>
                          <div className="div-block-244">
                            <p className="quote">Jessica Wilson</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      role="listitem"
                      className="div6 slide_item w-dyn-item"
                      style={{
                        transform:
                          slidee === "div1"
                            ? "translateX(0px)"
                            : slidee === "div2"
                            ? window.innerWidth >= 500
                              ? "translateX(-400px)"
                              : "translateX(-355px)"
                            : slidee === "div3"
                            ? window.innerWidth >= 500
                              ? "translateX(-800px)"
                              : "translateX(-710px)"
                            : slidee === "div4"
                            ? window.innerWidth >= 500
                              ? "translateX(-1200px)"
                              : "translateX(-1064px)"
                            : slidee === "div5"
                            ? window.innerWidth >= 500
                              ? "translateX(-1600px)"
                              : "translateX(-1418px)"
                            : slidee === "div6"
                            ? window.innerWidth >= 500
                              ? "translateX(-2000px)"
                              : "translateX(-1771px)"
                            : slidee === "div7"
                            ? window.innerWidth >= 500
                              ? "translateX(-2400px)"
                              : "translateX(-2125px)"
                            : window.innerWidth >= 500
                            ? "translateX(-2400px)"
                            : "translateX(-2125px)",
                      }}
                    >
                      <div className="slide_card">
                        <div className="div-block-243">
                          <p className="quote">
                           INRx has significantly impacted my professional
                            development. The platform's integration of Web3 and
                            DeFi technologies has given me practical knowledge
                            of decentralized applications and financial systems.
                            The experience of participating in liquidity
                            provision and yield farming has been particularly
                            rewarding. INRx's dedication to transparency and
                            security aligns with my goals, making it an
                            essential part of my career journey
                          </p>
                          <div className="div-block-244">
                            <p className="quote">-Rajesh Kumar</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slide_arrows">
                  <div className="slide_arrow is--left w-inline-block is--disabled-arrow m-1">
                    <div
                      className="slide_svg is--flip w-embed"
                      onClick={slideP}
                    >
                      <BsFillArrowLeftCircleFill size={50} />
                      {/* <img src={arrowL} alt="arrowL" width={100} /> */}
                    </div>
                  </div>
                  <div className="slide_arrow is--right w-inline-block m-1">
                    <div className="slide_svg w-embed" onClick={slide}>
                      <BsFillArrowRightCircleFill size={50} />
                      {/* <img src={arrowR} alt="arrow" width={100} /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="spacer">
            <div className="section-container--">
              <h2 className="heading">
                <span className="span-gradient">Join </span> Our Fearless Team
                at INRx
              </h2>
              <p className="text-gray">
                We are seeking risk-takers with diverse experience, ready to
                provide fearless feedback. At INRx, you're in great company.
                Join us and be part of a forward-thinking group dedicated to
                pioneering Web3, DeFi, and blockchain solutions. Together, we're
                shaping the future of decentralized finance.
              </p>
              <div className="row spacer justify-content-center">
                <div role="list" className="collection-list-6 w-dyn-items">
                  <div role="listitem" className="col-lg-4">
                    <a
                      href="#"                      
                      rel="noreferrer"
                      className="job-posting w-inline-block"
                    >
                      <div className="div-block-220">
                        <h3 className="reward-name-copy">
                          Back End Engineer
                        </h3>
                        <div className="job-posting-description">
                          Remote 
                        </div>
                        <div className="job-posting-description">Full Time</div>
                      </div>
                      <div className="text-block-44">›</div>
                    </a>
                  </div>
                  <div role="listitem" className="col-lg-4">
                    <a
                      href="#"                      
                      rel="noreferrer"
                      className="job-posting w-inline-block"
                    >
                      <div className="div-block-220">
                        <h3 className="reward-name-copy">Web3 Developer</h3>
                        <div className="job-posting-description">
                          Remote
                        </div>
                        <div className="job-posting-description">Full Time</div>
                      </div>
                      <div className="text-block-44">›</div>
                    </a>
                  </div>
                  <div role="listitem" className="col-lg-4">
                    <a
                      href="#"                      
                      rel="noreferrer"
                      className="job-posting w-inline-block"
                    >
                      <div className="div-block-220">
                        <h3 className="reward-name-copy">
                          Blockchain Advisor
                        </h3>
                        <div className="job-posting-description">Remote</div>
                        <div className="job-posting-description">Full Time</div>
                      </div>
                      <div className="text-block-44">›</div>
                    </a>
                  </div>           
                
                </div>
              </div>
              <p className="text-center text-gray">
                Want to do something else? Email us at{" "}
                <a href="mailto:careers@inrx.io">careers@inrx.io</a>.
              </p>
            </div>
          </div>         
        </div>
        <Footer />
      </div>
    </>
  );
}
